import React from 'react';
import { useEffect, useState } from 'react';

export function InputField({has_visibible_property, type, placeholder, name, reff, ...props}) {
    const setVisibility = props.setVisibility;
    const is_visible = props.is_visible;   
    const is_blocked = (props.blocked == null ? false : props.blocked);
    const [value, setValue] = useState(props.value);
    const [show_message, setShowMessage] = useState(false);

    function toggleVisibility() {
        if (has_visibible_property) {
            setVisibility(prev_visible => !prev_visible);
        }
    }

    function handleChange (event) {
        setValue(event.target.value);
    }

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleMouseEnter = () => {
        if (is_blocked) {
            setShowMessage(true);
        }
    };

    const handleMouseLeave = () => {
        setShowMessage(false);
    };
    
    return (
        <div className = {`flex flex-col ${props.className} relative`}>
            <div className="relative flex flex-row">
                <label htmlFor={name} className = {props.is_small ? "text-lg" : "text-xl font-bold"} >{name}</label>
                {has_visibible_property ? 
                    <div className="absolute right-0 ml-5 items-start">
                        <button onClick = {toggleVisibility} className = "flex text-xl font-bold">
                            <img src = {is_visible ? "https://img.icons8.com/material-outlined/24/000000/visible--v1.png" : "https://img.icons8.com/material-outlined/24/000000/invisible.png"} />
                            <span className = "ml-2">
                                {is_visible ? "Hide" : "Show"}
                            </span>
                        </button>
                    </div> 
                : null}
            </div>
            <input type = {is_visible ? 'text' : type} readOnly = {is_blocked} id = {name} ref = {reff} className = {`${props.is_small ? "text-lg border" : "text-xl border-2"} px-2 w-full border-black h-[5vh] ${is_blocked ? "bg-gray-300" : "bg-white"}`}  placeholder = {placeholder} value={value} onChange={handleChange}
                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
            {show_message && (
                <div className="absolute  p-2 bg-red-500 text-white text-sm rounded-md shadow-lg">
                    You cannot change this property
                </div>
            )}
        </div>
    );
}
