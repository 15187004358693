import React, {useState, useRef, useEffect} from 'react';
import SideBar from './SideBar.tsx'
import Template from './Template.tsx';
import SingleShadowedTemplateDescription from './SingleShadowedTemplateDescription.tsx';
import { useNavigate } from 'react-router-dom';

function TemplatesGrid({ setActiveTemplate }) {
    const navigate = useNavigate();

    const hover_colors = ["hover:bg-purple-500",  "hover:bg-green-500", "hover:bg-yellow-500", "hover:bg-red-500", "hover:bg-yellow-500"
                                , "hover:bg-rose-500", "hover:bg-lime-500", "hover:bg-blue-500", "hover:bg-indigo-500", "hover:bg-cyan-500", "hover:bg-emerald-500"];
                                
    const colors_of_templates = ["bg-purple-700",  "bg-green-700", "bg-yellow-700", "bg-red-700", "bg-yellow-700",
                                "bg-rose-700", "bg-lime-700", "bg-blue-700", "bg-indigo-700", "bg-cyan-700", "bg-emerald-700"];
    
    const array_of_templates = [{name: "General template", description: "Tell about your business, and chatbot will answer any question", type : 'basic'},
                                {name: "Catalogue template", description: "Upload your products, and chatbot will help to find the right one", type: 'offers'},
                               ];
                                
    const handleTemplateClick = (template, index) => {
        return () => {
            const description = {
                'popularity': '10',
                'name': template.name,
                'description': template.description,
                'color': colors_of_templates[index % colors_of_templates.length],
                'button_hover_color': hover_colors[index % hover_colors.length],
                'try_click': handleTryClick(template, index),
                'type': template.type || 'basic'
            };
            setActiveTemplate(description);
        };
    };

    const handleTryClick = (template, index) => {   
        return () => {
            console.log("Try button clicked");
            navigate('/brains', {state: {template: template}});
        }
    };

    return (<div className="ml-12 mt-10 grid grid-cols-3 gap-8 ">
    {array_of_templates.map((obj, index) => (
      <Template key={index} name={array_of_templates[index % array_of_templates.length].name} description={array_of_templates[index % array_of_templates.length].description} 
                className={`cursor-pointer ${colors_of_templates[index % colors_of_templates.length]} ${hover_colors[index % hover_colors.length]} `} 
        onClick={handleTemplateClick(obj, index)} />
    ))}
  </div>);
}

function LoadParameters(active_template) {
    let props = {
        'popularity': '10',
        'name': active_template.name,
        'description': active_template.description,
        'color': 'bg-red-500',
        'button_hover_color': 'bg-red-700'
    };
    // probably should fetch this from the backend
}

function RightPanel() {
    const [active_template, setActiveTemplate] = useState(null);
    return (<div className="flex flex-col w-[85%] h-[100vh]">
        <span className="ml-[5vw] mt-10 text-5xl font-bold">Templates</span>
        <div className="ml-10 border-gray-200 border-b-2 mr-10 mt-4"></div>
        <div className = "relative h-full w-full">
            <TemplatesGrid setActiveTemplate={setActiveTemplate}/>
            {active_template && SingleShadowedTemplateDescription({...active_template, setActiveTemplate})}
        </div>
    </div>);
}

function TemplatesPage() {
    return (<div className = "flex ">
            <SideBar active_button="Templates" />
        <div className="child-line transform -translate-x-1/2 h-[100vh] w-0.5 bg-gray-200" />
            <RightPanel />
        </div>);
}

export default TemplatesPage;