import React, {useEffect, useRef, useState} from "react"
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Flip } from "gsap/Flip";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { CSSPlugin } from "gsap/CSSPlugin"; // Import the CSSPlugin
import { RoughEase } from "gsap/EasePack";
import { TextPlugin } from "gsap/TextPlugin";
import { PopupModal } from "react-calendly";


gsap.registerPlugin(TextPlugin,RoughEase);
gsap.registerPlugin(useGSAP, Flip,ScrollTrigger,ScrollToPlugin, CSSPlugin);

import phone_logo from "../img/phone_logo.svg";
import Logo from "./Logo.tsx";
import our_person_logo from "../img/our_person_logo.png";
import land_our_message_logo from "../img/land_our_message_logo.svg";
import land_opp_message_logo from "../img/land_opp_message_logo.svg";
import opp_message_logo from "../img/opp_message_logo.svg";
import land_telegram_logo from "../img/land_telegram_logo.svg";
import land_whatsapp_logo from "../img/land_whatsapp_logo.svg";
import land_messenger_logo from "../img/land_messenger_logo.svg";
import land_instagram_logo from "../img/land_instagram_logo.svg";
import mouser_cursor_logo from "../img/mouser_cursor_logo.svg";
import first_question from "../img/first_question.svg";
import second_question from "../img/second_question.svg";
import third_question from "../img/third_question.svg";
import {useNavigate} from "react-router-dom";

 
function Header() {
    useEffect(() => {
        function enterAnimation(link, e, index) {
            link.tl.tweenFromTo(0, "midway");
        }
          
        function leaveAnimation(link, e) {
            link.tl.play();
        }
                    
        // Get all links
        let workLinks = document.querySelectorAll(".header-option");
          
        workLinks.forEach((link, index, value) => {
            let underline = link.querySelector(".underline-text");
            link.tl = gsap.timeline({paused: true});
            link.tl.add("start");
            link.tl.fromTo(underline, {
                width: "0%",
                left: "0%",
            }, {
                width: "100%",
                duration: 0.3, 
            });
              
            link.tl.add("midway");
              
            link.tl.fromTo(underline, {
                width: "100%",
                left: "0%",
            }, {
                width: "0%",
                left: "100%",
                duration: 0.1, 
                immediateRender: false
            });
            link.tl.add("end");
            
            // Mouseenter
            link.addEventListener("mouseenter", (e) => {
                enterAnimation(link, e, index);
            });
            
            // Mouseleave
            link.addEventListener("mouseleave", (e) => {
                leaveAnimation(link, e);
            });
            link.tl.eventCallback("onPause", () => {
                // Do something when the timeline is paused
                console.log("Timeline paused");
            });
              
        });

        return () => {
            workLinks.forEach((link, index, value) => {
                link.removeEventListener("mouseenter", (e) => {
                    enterAnimation(link, e, index);
                });
                link.removeEventListener("mouseleave", (e) => {
                    leaveAnimation(link, e);
                });
                link.tl.clear();
                link.tl.kill();

            });
        }
    }, []);

    const navigate = useNavigate();
    const handleGetStartedClick = () => {
        navigate("/register");
    };

    const [show_book_demo, setShowBookDemo] = useState(false);
    const handleBookDemoClick = () => {
        setShowBookDemo(true);
    };

    useGSAP(() => {
        gsap.fromTo("#land-header", {paddingTop:"1.25rem", paddBottom: "1rem"}, {paddingTop: "0.25rem", paddingBottom:"0.25rem", scrollTrigger: { trigger: "#land-first-text", start: "top 9%", end: "top 9%", scrub: 1}});
        gsap.fromTo("#landpage-header-border", {borderColor: "transparent"}, {borderColor: "rgb(209 213 219)", scrollTrigger: { trigger: "#land-first-text", start: "top 9%", end: "top 9%", scrub: 1}});
    });


    return (<header className = "fixed z-20 w-full">
        <div className = "flex flex-row items-center justify-between bg-white px-[10vw]" id = "land-header">
            <Logo />
            <ul className = "flex flex-row font-bold text-xl">
                <li className = "flex relative flex-col header-option cursor-pointer" onClick={handleGetStartedClick}>
                    <p>Get started </p>
                    <span className="underline-text"></span>
                </li>
                <li className = "ml-5 flex relative flex-col header-option cursor-pointer" onClick={handleGetStartedClick}>
                    <p>Sign in</p>
                    <span className="underline-text"></span>
                </li>
            </ul>
            <div>
                <button className = "header-button p-3 rounded-2xl" onClick = {handleBookDemoClick}>
                    <span className = "button-text">
                        Book a demo
                    </span>
                </button>
                <PopupModal
                    url="https://calendly.com/bogdantolstik/30min?preview_source=et_card"
                    onModalClose={() => setShowBookDemo(false)}
                    open={show_book_demo}
                    /*
                    * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                    * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                    */
                    rootElement={document.getElementById("root")}
                    />
            </div>
        </div>
        <div className=" border-b-2" id="landpage-header-border"></div>
    </header>);
}

function LandingMyMessage (props) {
    return (
    <div className = {`absolute landpage-message ${props.className}`}>
        <div className = "land-our-message text-white rounded-2xl p-3 relative max-w-[15vw]">
            <p className = "text-white text-xl font-bold">{props.text}</p>
            <img src={land_our_message_logo} className = "absolute right-0 w-10 h-10" />
        </div>
    
        <img src = {our_person_logo} className = "absolute rounded-full right-[-4.5vw] w-[7rem]" />
    </div>
    );
}

function LandingOppMessage (props) {
    return (
    <div className = {`absolute landpage-message ${props.className}`}>
        <div className = "land-opp-message text-white rounded-2xl p-3 relative max-w-[15vw]">
            <p className = "text-white text-xl font-bold ">{props.text}</p>
            <img src={land_opp_message_logo} className = "absolute left-[0vw] w-10 h-10" />
        </div>
    
        <img src = {opp_message_logo} className = "absolute bottom-[-7.5vh] left-[-3.5vw] rounded-full w-[4rem]" />
    </div>
    ); 
}

function FirstPage() {
    
    useGSAP(() => {
        let messages = document.querySelectorAll(".landpage-message");
        messages.forEach((message, index, value) => {
            gsap.fromTo(message, {y: "-2vh"}, {y: "3vh", duration: 1, ease: "power2.inOut", scrollTrigger: { trigger: ".land-firstpage", start: "20% center", end: "bottom center", scrub: 2}});
        });
    });

    const navigate = useNavigate();

    const handleGetStartedClick = () => {
        navigate("/register");
    }; 

    return (
    <div className = "relative land-firstpage mt-[10vh] h-[90vh] w-[100%]">
        <div className = "relative h-[100%] w-[100%] flex flex-col items-center pt-[4vh] landing-firstpage-bg">
                <p className = "text-center text-6xl font-bold landing-gradiented-text py-2" id = "land-first-text">
                    Unleash the power of AI for <br/> your business in couple clicks 
                </p>
                <p className = "text-center text-2xl font-bold text-gray-400 mt-4">
                    Make AI engage with your customers to <br /> drive your more sales on social media.
                </p>
                <button className = "bg-black text-white p-3 rounded-2xl mt-6 text-xl font-bold " onClick={handleGetStartedClick}>Get Started for free</button> 
                <img src = {phone_logo} className = "absolute bottom-0 w-[100vw] h-[50vh]" />
        </div>
        <LandingMyMessage className = "absolute left-[50vw]  bottom-[33vh] z-10" text = "Hello! I want to get 10% off my first order"/>
        <LandingOppMessage className = "absolute left-[38vw] bottom-[25vh] z-10" text = "Sure! Let me help you with that"/>
        <LandingMyMessage className = "absolute left-[50vw]  bottom-[15vh] z-10" text = "Okay! Its btolstik@gmail.com"/>
        <LandingOppMessage className = "absolute left-[38vw] bottom-[8vh] z-10" text = "Sure! Let me help you with that"/>
    </div>
    )
}

function MessengerLogo(props) {
    return (<img src={props.logo} className = {`h-[15vh] w-[15vw] rounded-full ${props.className}`}/>)
}

function ChatsPage() {
    
    useGSAP(() => {
        gsap.fromTo(".telegram", {top: "50vh", right: "45vw", opacity: 0}, {opacity: 1, top: "22.5vh", right: "30vw", duration: 1, scrollTrigger: { trigger: ".messengerpage_ref", start: "70% bottom", end: "20% top", scrub: 2}});
        gsap.fromTo(".instagram", {top: "50vh", right: "45vw", opacity: 0}, {opacity: 1, top: "22.5vh", left: "30vw", duration: 1, scrollTrigger: { trigger: ".messengerpage_ref", start: "70% bottom", end: "20% top", scrub: 2}});
        gsap.fromTo(".messenger", {top: "50vh", right: "45vw", opacity: 0}, {opacity: 1, bottom: "30vh", right: "15vw", duration: 1, scrollTrigger: { trigger: ".messengerpage_ref", start: "70% bottom", end: "20% top", scrub: 2}});
        gsap.fromTo(".whatsapp", {top: "50vh", right: "45vw", opacity: 0}, {opacity: 1, bottom: "30vh", left: "15vw", duration: 1, scrollTrigger: { trigger: ".messengerpage_ref", start: "70% bottom", end: "20% top", scrub: 2}});
    });

    useGSAP(() => {
        gsap.from(".chatpage-main-text", {opacity: 0, duration: 1, scrollTrigger: { trigger: ".chatpage-main-text", start: "top 80%", end: "bottom top"}});
    });
    
    return (<div className = "messengerpage_ref w-[100%] h-[100vh] relative bg-white">
            <MessengerLogo  logo = {land_telegram_logo} className = "telegram absolute"/>
            <MessengerLogo logo = {land_instagram_logo} className = "instagram absolute"/>
            <MessengerLogo  logo = {land_messenger_logo} className = "absolute messenger"/>
            <MessengerLogo  logo = {land_whatsapp_logo} className = "absolute whatsapp"/>
            <p className = "absolute bottom-[35vh] left-[32.5vw] chatpage-main-text text-7xl font-bold text-black"> 
                One chatbot instantly <br>
                </br>available everywhere!
            </p>

    </div>);    
}

function ThirdPage() {
    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".third_page",
                start: '20% center', 
                end: '70% center',
            },
            defaults: {duration: 0.5},
        });
        
        tl.to(".third_page_button", { paddingLeft: "3vw", paddingRight:"4vw", paddingBottom: "5vw", paddingTop:"5vw"}),
        tl.fromTo(".third_page_large_text", {fontSize: "1.875rem", lineHeight: "2.25rem"}, {fontSize: "4.5rem", lineHeight: "1rem"}, "<");
        tl.fromTo(".third_page_small_text", {fontSize: "1.125rem", lineHeight: "1.75rem", marginTop:"1vh"}, {fontSize: "1.875rem", lineHeight: "2.25rem", marginTop:"5vh"}, "<");
        tl.to(".third_page_button", { scale: 1.1, ease: "bounce.out", duration: 1}),
        tl.fromTo(".third_page_button", {marginLeft: "-30vw"}, {marginLeft: "5vw",duration: 0.5, ease: 'back.inOut'}, ">0.25");
        tl.fromTo(".third_page_left_text", {opacity: 0}, {opacity: 1, duration: 0.4, delay: 0.2});
    });


    const container = useRef(null);

    useEffect( () => {
        const mouseMove = ((event) => {
            const start_y = document.querySelector('.third_page').getBoundingClientRect().top;
            const finish_y = document.querySelector('.third_page').getBoundingClientRect().bottom;
            if (event.clientY < start_y) {
                return;
            }
            if (event.clientY > finish_y) {
                return;
            }
            const x = event.clientX - 15;
            let y = event.clientY - 15;
            y -= start_y;
            const cursor = document.querySelector('.thirdpage_cursor');
            cursor.style.left = x + 'px';
            cursor.style.top = y + 'px';
        });
        window.addEventListener('mousemove', mouseMove);

        return () => {
            window.removeEventListener('mousemove', mouseMove);
        };
    }, []);

    
    useGSAP(() => {
        gsap.from(".thirdpage-main-text", {opacity: 0, duration: 1, scrollTrigger: { trigger: ".thirdpage-main-text", start: "top bottom", end: "bottom 80%"}});
    });

    return (<div ref = {container} className = "w-[100%] h-[100vh] third_page relative bg-white land-thirdpage-bg">
        <div className = "flex flex-col items-center w-[100%] mt-[15vh]">
            <span className = "text-6xl text-center font-bold thirdpage-main-text text-white">
                Integrate the state-of-art tech <br/>
                without ANY CODE
            </span>
        </div>
        <div className = "flex flex-row items-center relative justify-center mt-[10vh] ">
            <p className = "text-white text-4xl third_page_left_text">
                    Use prebyte templates, write text <br /> instructions without any experience with <br/> LLM or programming
            </p>

            <div className = " bg-white third_page_button text-black rounded-[2vw]">
                <p className = " font-bold third_page_large_text"> Assistant</p>
                <p className = " font-bold third_page_small_text "> Make a sample version of your <br />service when you’re away</p>
            </div>

        </div>
        <img src = {mouser_cursor_logo} className = "thirdpage_cursor absolute w-[3vw]" />

    </div>);    
}


function QuestionAndAnswer(props) {
    const textWithBreaks = <div>
        {props.answer.split('\n').map((part, index) => (
            <span key={index}>
                {part}
                {/* Add a <br> tag except for the last part */}
                {index < props.answer.split('\n').length - 1 && <div><br/></div>}
            </span>
        ))}
    </div>;
    return (<div className = {`${props.className} flex flex-col`}>
        <p className = "text-6xl font-bold   leading-tight">{props.question}</p>
        <p className = "text-4xl mt-3 font-bold text-gray-400">{textWithBreaks}</p>
    </div>);
}

function QuestionWithPhoto(props) {
    const name = "question_with_photo_" + props.id;
    console.log(name);
    const image_name = name + "img";
    const qna = name + "qna";
    
    useGSAP(() => {
        const start_left = props.isLeft ? "30vw" : "";
        const finish_left = props.isLeft ? "0vw" : "";
        const start_right = props.isLeft ? "" : "30vw";
        const finish_right = props.isLeft ? "" : "0vw";
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: "." + name,
                start: 'top center', 
                end: 'bottom center',
            },
        });

        tl.fromTo("."+image_name, {opacity: 0, marginLeft: start_left, marginRight: start_right, duration: 1.0}, {opacity: 1, marginLeft: finish_left, marginRight: finish_right, duration: 0.5});

        tl.fromTo("."+qna, {opacity: 0, duration: 0.25},  {opacity: 1, duration: 0.25});

    });

    if (props.isLeft) {
        return (<div className = {`${name} h-[65vh] flex flex-row justify-evenly items-center pl-[5vw] pr-[15vw]`}>
            <img src = {props.photo} className = {`w-[40vw] ${image_name} opacity:0 `} />
            <QuestionAndAnswer className = {`${qna} opacity:0`} question = {props.question} answer = {props.answer} />
        </div>);
    } else {
        return (<div className = {`${name} h-[65vh] flex flex-row justify-evenly items-center pr-[5vw] pl-[15vw]`}>
            <QuestionAndAnswer className = {`${qna} opacity:0`} question = {props.question} answer = {props.answer} />
            <img src = {props.photo} className = {`w-[40vw] ${image_name}`} />
        </div>);
    }
}


function FourthPage() {

    return (<div className = "relative w-[100%] flex flex-col pt-[10vh] pb-[20vh]">
            <p className = "text-center text-7xl font-bold landing-gradiented-text py-2">
                Stop loosing leads and <br/> delegate the followup to AI
            </p>
            <QuestionWithPhoto isLeft = {true} id = {0} photo = {first_question} question = "Keep struggling with lead follow-up?" answer = {"Thanks to presence at social media, \
                                                                                        our AI will automatically remind customer about your product for 100 times lower price than retargeting. \
                                                                                        \n \
                                                                                     You can also set up manually any marketing information that you want to send to your leads! "}/>
            <QuestionWithPhoto isLeft = {false} id = {1} photo = {second_question} question = "Supercharge your lead generation!" answer = {" Have the same information to gather from all the leads? Tired of answering the same questions?\n \
                                                                                        Agentify AI chatbot will adress all of it.\
                                                                                        It will gather inquires from your customers and adress all the aspects of your business."}/>

            <QuestionWithPhoto isLeft = {true} id={2} photo = {third_question} question = "Give value of your service upfront, and close more clients faster!" answer = {"You can use your own AI chatbot \
to give some short but useful advice, \
that people will turn to and later buy your full service.\n \
No effort for you, more value for them, \more appoint "}/>

    </div>)
}

function LastPage() {

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".land-lastpage-bg",
                start: 'top 80%', 
                end: 'bottom center',
            },
            defaults: {duration: 0.5},
        });
        tl.from(".lastpage_anything", {scale: 0.5, opacity: 0, duration: 1, ease: "back.out(1.7)"});
    });

    const words = ["don't leave behind.", 'drive more sales.', 'automate your routine.'];
    
    useGSAP(() => {
        const masterTl = gsap.timeline({
            repeat: -1
        });
        words.forEach((word) => {
            let tl = gsap.timeline({repeat: 1, yoyo: true, repeatDelay: 1});
            tl.to("#changing_text", {text: word, duration: 1.25, delay: 0.25});
            masterTl.add(tl);
        });
    });

    useGSAP(() => {
        gsap.to("#cursor", {opacity: 0, repeat: -1, duration: 0.5, ease: "power2.inOut"});
    });

    
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/register");
    }; 

    return (<div className = " w-[100%] h-[70vh] pb-[10vh] pt-[20vh] flex flex-col items-center land-lastpage-bg">
        <p className = "text-3xl font-bold">
            but also you can do...
        </p>

        <p className = "text-8xl font-bold lastpage_anything">
            ANYTHING
        </p>
        <div className = "flex flex-row items-center mt-5 font-bold text-5xl w-[100%] ">
            <p className="text-center ml-[37vw] leading-tight">
                Use AI, 
            </p>
            <span className="text-center leading-tight ml-3" id = "changing_text">
            </span>
            <span className="" id = "cursor">
                |
            </span>
        </div>
        <button className = "bg-black text-white  p-3 rounded-2xl mt-12 text-2xl px-[4vw] font-bold" onClick={handleButtonClick}>Feel the future</button> 
    </div>)
}

function Footer() {
    useEffect(() => {
        function enterAnimation(link, e, index) {
            link.tl.tweenFromTo(0, "midway");
        }
          
        function leaveAnimation(link, e) {
            link.tl.play();
        }
                    
        // Get all links
        let workLinks = document.querySelectorAll(".footer-option");
          
        workLinks.forEach((link, index, value) => {
            let underline = link.querySelector(".underline-text");
            link.tl = gsap.timeline({paused: true});
            link.tl.add("start");
            link.tl.fromTo(underline, {
                width: "0%",
                left: "0%",
            }, {
                width: "100%",
                duration: 0.3, 
            });
              
            link.tl.add("midway");
              
            link.tl.fromTo(underline, {
                width: "100%",
                left: "0%",
            }, {
                width: "0%",
                left: "100%",
                duration: 0.1, 
                immediateRender: false
            });
            link.tl.add("end");
            
            // Mouseenter
            link.addEventListener("mouseenter", (e) => {
                enterAnimation(link, e, index);
            });
            
            // Mouseleave
            link.addEventListener("mouseleave", (e) => {
                leaveAnimation(link, e);
            });
            link.tl.eventCallback("onPause", () => {
                // Do something when the timeline is paused
                console.log("Timeline paused");
            });
              
        });

        return () => {
            workLinks.forEach((link, index, value) => {
                link.removeEventListener("mouseenter", (e) => {
                    enterAnimation(link, e, index);
                });
                link.removeEventListener("mouseleave", (e) => {
                    leaveAnimation(link, e);
                });
                link.tl.clear();
                link.tl.kill();

            });
        }
    }, []);
    return (<footer className = "relative h-[12vh]">
        <div className=" border-gray-100 border-b-2"></div>
        <div className = "flex flex-row items-center h-full justify-between px-[15vw]">
            <Logo />
            <div className = "flex flex-col">
                <ul className = "flex flex-row font-bold text-2xl">
                    <li className = "footer-option flex relative flex-col cursor-pointer">
                        <p> Help </p>
                        <span className="underline-text"></span>
                    </li>
                    <li className = "footer-option ml-6 flex relative flex-col cursor-pointer">
                        <p> Feedback </p>
                        <span className="underline-text"></span>
                    </li>
                    <li className = "footer-option ml-6 flex relative flex-col cursor-pointer">
                        <p> Sign in </p>
                        <span className="underline-text"></span>
                    </li>
                </ul>
                <div className = "flex flex-row items-center">
                    <p className = "text-gray-400 text-xl">Privacy Policy</p>
                    <p className = "text-gray-400 text-xl ml-5">Terms</p>
                    <p className = "text-gray-400 text-xl ml-5">@ 2024</p>
                </div>
            </div>
        </div>
        
    </footer>);
}
export default function LandingPage() {

    // useGSAP(() => {
    useGSAP(() => {
        let buttons = document.querySelectorAll('button');
        buttons.forEach((button, index, value) => {
            // Add event listeners to play and reverse the timeline on hover
            button.addEventListener('mouseenter', () => {
                gsap.to(button, {duration: 0.5,   boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.5)' });
            });

            button.addEventListener('mouseleave', () => {
                gsap.to(button, {duration: 0.5,   boxShadow: 'none'});
            });
        });

        return () => {
            buttons.forEach((button, index, value) => {
                button.removeEventListener('mouseenter', () => {
                    gsap.to(button, {duration: 0.5,   boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.5)',            });
                });
                button.removeEventListener('mouseleave', () => {
                    gsap.to(button, {duration: 0.5,   boxShadow: 'none',            });
                });
            });
        };
    });

    return (<div className = "flex scrollbar-none bg-white flex-col overflow-x-hidden">   
            <Header />
            <FirstPage />
            <ChatsPage />
            <ThirdPage />
            <FourthPage />
            <LastPage />
            <Footer />
        </div>
    );

}