import React, { useState, useEffect, useRef } from 'react';
import HomePage from './components/HomePage.tsx';
import StatsPage from './components/StatsPage.tsx';
import TemplatesPage from './components/TemplatesPage.tsx';
import BotBrainsPage from './components/BotBrainsPage.tsx';
import { Routes, Route, Navigate } from 'react-router-dom';
import LiveChatPage from './components/LiveChatPage.tsx';
import SettingsPage from './components/SettingsPage.tsx';
import RegistrationPage from './components/RegistrationPage.tsx';
import {axiosPrivate, getLocalStorageToken} from './hooks/axios.js';
import LoginPage from './components/LoginPage.tsx';
import {AppContext} from './AppContext.js'
import useOnNotify from './hooks/useOnNotify.tsx';
import LandingPage from './components/LandingPages.tsx';
import { Lenis, ReactLenis, useLenis } from '@studio-freight/react-lenis'
import ConfigurationPage from './components/ConfigurationPage.tsx'
import { ToastContainer } from 'react-toastify';
import Loading from './components/Loading.tsx';
import { useContextSelector } from "use-context-selector";
import ProfilePage from './components/ProfilePage.tsx';
import PrivacyPolicy from './components/PrivacyPolicy.tsx';

const ProtectedRoute = ({ children }) => {
  const storage_token = getLocalStorageToken();
  if (!storage_token || storage_token == undefined) {
    return <Navigate to="/login" />;
  }
  return children;
};


function StateProvider({children}) {
  const [addNotifyCallback, removeNotifyCallback] = useOnNotify();
  const [shadowLoading , setShadowLoading] = useState("");
  return (
    <AppContext.Provider value={{addNotifyCallback, removeNotifyCallback, setShadowLoading}} >
        <Loading loading={shadowLoading=="full"} size="full" />
        {children}
    </AppContext.Provider>
)
}



function App() {
  useEffect(()=>{ 
    console.log("here");
    try{
      const storage_token = getLocalStorageToken();
      console.log(storage_token);
      if (storage_token != null) {
        axiosPrivate.defaults.headers['Authorization'] = `Bearer ${storage_token}`;
      }
    } catch (e) {
      console.log(e);
    }
  });



  return <StateProvider >
    <Routes >  
      <Route path="home/*" element={
        <ProtectedRoute>
          <HomePage />
        </ProtectedRoute>
      } />
      <Route path="stats/*" element={     
        <ProtectedRoute>
          <StatsPage />
        </ProtectedRoute> 
      }/>
      <Route path="templates/*" element={     
        <ProtectedRoute>
          <TemplatesPage />
        </ProtectedRoute> 
      }/>
      <Route path="chat/*" element={     
        <ProtectedRoute>
          <LiveChatPage />
        </ProtectedRoute> 
      }/>
      <Route path="brains/*" element={     
        <ProtectedRoute>
          <BotBrainsPage />
        </ProtectedRoute> 
      }/>
      <Route path="settings/*" element={     
        <ProtectedRoute>
          <SettingsPage />
        </ProtectedRoute> 
      }/>
      <Route path="/register/*" element={getLocalStorageToken() ? <Navigate to="/home" /> : <RegistrationPage />} />
      <Route path="/login/*" element={getLocalStorageToken() ? <Navigate to="/home" /> : <LoginPage />} />
      <Route path="/" element={     
        <Lenis root>
          <LandingPage/>
        </Lenis> 
      }/>
      <Route path="/book-demo" element={<Navigate to="/register" />} />
      <Route path="/configure/*" element={<ConfigurationPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      {/* <Route path="*" element={getLocalStorageToken() ? 
      <Navigate to="/home" /> 
      : <Navigate to="/" />} /> */}
      <Route path="*" element={<Navigate to="/" />} />
     </Routes>
     <ToastContainer />
    </StateProvider >;
}

export default App;
