import React from "react"

const kPropertyLimit = 10;

function getShowingName(first_name: string, last_name : string) {
    if (first_name == null && last_name == null) {
        return "N/A";
    }
    if (first_name != null && first_name.length > kPropertyLimit) {
        first_name = first_name.substring(0, kPropertyLimit - 2) + "..";
    } else if (first_name == null) {
        first_name = '';
    }
    if (last_name != null && last_name.length > kPropertyLimit) {
        last_name = last_name.substring(0, kPropertyLimit - 2) + "..";
    } else if (last_name == null) {
        last_name = '';
    }
    return first_name + " " + last_name;
}

function Name(props){
    const user = props?.user;
    return <span className = {`${props.className}`}>{getShowingName(user?.first_name, user?.last_name)} </span>;
}

export default Name;