import React from 'react';
import TemplateDescription from './TemplateDescription.tsx';

export default function SingleShadowedTemplateDescription({setActiveTemplate, ...props}) {
    const handleChooseAnotherClick = (event) => {
        if (event.target.className.includes('template-shadow')) {
            setActiveTemplate(null);
        }
    };
    return <div className = "absolute left-0 top-0 h-full w-full z-10 flex justify-center items-center bg-black bg-opacity-80 template-shadow" onClick = {handleChooseAnotherClick}>
        <TemplateDescription popularity = {props.popularity} name = {props.name} description = {props.description} color = {props.color}
                             button_hover_color = {props.button_hover_color} className = {` z-20`} try_click = {props.try_click} setActiveTemplate={setActiveTemplate}/>
    </div>
}