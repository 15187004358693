import React from "react"
import Avatar from "./Avatar.tsx"

function MyMessage(props){
    return (<div className={`mt-3 ${props.className} justify-end flex`}>
        <span className=" bg-blue-200 rounded-lg px-3 py-2 font-bold text-lg break-all max-w-[75%]">
            {props?.text}
        </span>
        <Avatar className = "ml-3 w-9 h-9 mt-1" user_photo = {props?.photo_url} />
    </div>);
}

export default MyMessage;