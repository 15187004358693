import React, { useContext } from "react"
import {useState, useRef, useEffect } from "react"
import chatCss from "./chat.module.css"
import Avatar from "./Avatar.tsx"
import MyMessage from "./MyMessage.tsx"
import OppMessage from "./OppMessage.tsx"
import send_message_button_logo from "../../img/send_message_button_logo.svg"
import { useContextSelector } from "use-context-selector"
import { axiosPrivate } from "../../hooks/axios.js"
import { AppContext } from "../../AppContext.js"
import { toast, ToastContainer } from 'react-toastify';
import Name from './Name.tsx'
import error_platfrom_access_logo from "../../img/error_platfrom_access_logo.svg"
import error_empty_user_chatroom_logo from "../../img/error_empty_user_chatroom_logo.svg"
import chatOpenHandle from "../../hooks/chatOpenHandle.tsx"
import { error_notification } from "../../hooks/utils.tsx"

function EmptyChatRoom({platform}) {
    return (<div className = "flex flex-col h-[100%] w-full items-center">
            <img loading="lazy" src = {error_platfrom_access_logo} className = " w-1/2 h-2/5 mt-[10vh]" />
            <span className = 'text-3xl font-bold mt-[-6vh]'> No token found for {platform} </span>
            <span className = 'text-2xl mt-4'> Please set the token in settings </span>
        </div>)
}


function EmptyUserChatRoom() {
    return (<div className = "flex flex-col h-[100%] w-full items-center">
            <img loading="lazy" src = {error_empty_user_chatroom_logo} className = " w-1/2 h-2/5 mt-[10vh]" />
            <span className = 'text-3xl font-bold'> We couldn't find chat </span>
            <span className = 'text-2xl  mt-3'> Please select one</span>

        </div>)
}

function ChatRoom({has_token, user, platform}){
   
    const [showM, setShowM] = useState([]); /// messages to show
    const [textValue, setTextValue] = useState(""); /// text input
   
    const addNotifyCallback = useContextSelector(
        AppContext,
        (state) => state.addNotifyCallback
    );
    const removeNotifyCallback = useContextSelector(
        AppContext,
        (state) => state.removeNotifyCallback
    );
    const targetRef = useRef(null); // For UI, not remember particularly important
    const userRef = useRef(null); // !!!! critically important for functions, since props.user ref is chaning but it HAS TO PERSIST
    
    
    const inputRef = useRef(null);

    useEffect(()=>{
        if (user?.chat_id != null) {
            chatOpenHandle(user.chat_id, platform);
        }
    }, [user?.chat_id]);
   
    const handleChange = (e)=>{
        setTextValue(e.target?.value);
    }
    const sendMessage = async (themessage)=>{
        if(themessage != null && themessage != ""){ 
            axiosPrivate.post(`${process.env.REACT_APP_BACKEND_URL}/send-message`, {chat_id : user.chat_id, message : themessage, platform : platform}).then((reponse)=>{
                console.log("successfully sent");
            }).catch(e => {console.log("error while sending message " + e?.message)}); 
        }
    };


    const scrollToElement = () => { 
      if (targetRef.current) {
        console.log("hey");
        targetRef.current.scrollIntoView({ behavior: 'instant' });
      }
    };
    const acceptsInput = (elem) => { /// this is is to have keyboard in focus even when messages come through, maybe not, anyway it's for mobile I'm pretty sure
        if (!elem) { return false }
      
        let tag = elem.tagName
        return tag == 'INPUT' || tag == 'TEXTAREA';
    }
    const addMessage = (msg) => {
        setShowM(prev=>{
            const nwew = [...prev,  msg];
            return nwew;
        });
    };

    const onSend = (e)=>{
        e.preventDefault();
        inputRef.current.focus();
        const text = inputRef?.current?.value;
        inputRef.current.value="";
        if (text.length == 0) {
            error_notification("You can't send empty message");
            return;
        }
        setTextValue("");
        scrollToElement();
        addMessage(<MyMessage text={text} key = {showM.length} photo_url = {'public/default_profile.svg'}/>);
        sendMessage(text);
        axiosPrivate.post(`${process.env.REACT_APP_BACKEND_URL}/send-message`, {opponentId : user.userid, message : text}).then((reponse)=>{
            console.log("successfully sent");
        }).catch(e => {console.log("error while sending message " + e?.message)});
    
    };
    useEffect(()=>{
        userRef.current = user;
    }, [user]);


    useEffect(()=>{ // !!! Instant messaging goes here
       
        const callback = (data)=>{
            if(userRef.current && userRef.current.chat_id == data.chat_id){
                addMessage(<OppMessage  user={userRef.current}  text={data.message.content} />);
            }
        }
        
        addNotifyCallback('new_message', callback)

        return ()=>{
            removeNotifyCallback('new_message', callback)
        }
    }, [])
    
    useEffect(()=>{
        scrollToElement();
    });
    
    useEffect(()=>{
        if(user == null){
            return;
        }else{
            axiosPrivate.get(`${process.env.REACT_APP_BACKEND_URL}/get-messages/${platform}/${user.chat_id}`).then(response => {
                const messageToShow = response.data.messages;
                console.log(JSON.stringify(response.data));
                
                let toDisplay = [ <div className={chatCss.filler}>
                    </div>
                   ];
                for(let i = 0; i < messageToShow.length; ++i) {
                    
                    if(messageToShow[i].role == 'client') {
                        toDisplay.push(<OppMessage key = {i} user={user} text={messageToShow[i].content} />);
                    } else {
                        ///!!! here should be distinction between business messages and AI messages
                        let photo_url: string;
                        console.log(messageToShow[i]);
                        if (messageToShow[i].role == 'bot') {
                           photo_url = 'public/bot_profile_logo.svg';
                        } else {
                            photo_url = 'public/default_profile.svg';
                        }
                        toDisplay.push(<MyMessage key = {i} photo_url = {photo_url} text={messageToShow[i].content} />);
                    }
                }
                toDisplay.push(<div ref={targetRef} ></div>);
                setShowM(toDisplay);
            }).catch(e => {
                console.log("error while get-messages " + e?.message);
            });
        }
        const thelistener = (e) => {
            let target = e.target
            let dontDiscardKeyboard = target.classList.contains('do-not-hide-keyboard')
            if (acceptsInput(target)) {
                target.focus();
            }else{
                e.preventDefault();
                 if(target && (target.tagName === 'BUTTON' || target.tagName == 'IMG' || target.classList.contains("clickable"))){
                    target.click();
                }else{
                    document.activeElement.blur();
                }
                
            }
          };
        document.addEventListener('touchend', thelistener);
        return ()=>{
            document.removeEventListener("touchend", thelistener);
        }
    }, [user]);
   
    
    useEffect(() => {
        if (inputRef.current != null) {
            // We need to reset the height momentarily to get the correct scrollHeight for the textarea
            inputRef.current.style.height = "0px";
            const scrollHeight = inputRef.current.scrollHeight;
    
            // We then set the height directly, outside of the render loop
            // Trying to set this with state or a ref will product an incorrect value.
            inputRef.current.style.height = (scrollHeight+10) + "px";
        }
        }, [inputRef, textValue]);
   
   

    




    return (
    <>
        <ToastContainer />
        {has_token == false ? <EmptyChatRoom platform={platform}/> :  
        user == null ? <EmptyUserChatRoom /> :
        (<div className = "flex flex-col h-full w-full relative overflow-y-hidden">
            <div className="flex flex-col pt-4 absolute w-full z-10 bg-white">
                <div className = "flex flex-row ml-4 items-center">
                    <Avatar className = "w-10 h-10" user_photo ={user?.user_photo}  />
                    <Name className = "text-xl font-bold ml-4" user = {user} />
                </div>
                <div className=" border-gray-300 border border-b-3 mt-4 "></div>
            </div>
        
            <div className="flex flex-col px-2 max-h-[71vh] overflow-y-scroll chatroom_scrollbar absolute bottom-[10vh] w-full pb-[1vh]">
                {
                    showM
                }
            
            </div>
            
            <div className="flex flex-col absolute justify-between bottom-0 bg-white h-[10vh] w-full items-center">
                <div className=" w-full border-gray-300 border border-b-3"></div>
                <div className = "flex flex-row items-center w-full h-full justify-between">
                    <textarea  ref={inputRef} onChange={handleChange} name="text" rows={3} className="h-full focus:border-none focus:outline-none focus:ring-0 px-4 pt-4 resize-none w-full text-lg"  placeholder={"Type your message here"} />
                    <img loading="lazy"  className = "cursor-pointer mr-8 w-8 holer:filter hover:grayscale" src={send_message_button_logo} onClick={onSend}/>
                </div>
            </div>
        </div>)}
    </>);
}

export default ChatRoom;
