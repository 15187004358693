import React, { useState } from "react"


import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useContextSelector } from "use-context-selector";

import {transform_url} from '../../hooks/utils.tsx'

function Avatar(props){
    // console.log(props);
   // console.log(props.user != null ? props.user.photo : "");
   return  (<AnimatePresence>

        

        <div className = {` ${props.className}`}>
            { props.user_photo!=null  ? 
                (<img loading="lazy" className = "rounded-full w-full h-full" src={transform_url(props.user_photo)}  />)
                : (<div className="bg-gray-300 w-full h-full rounded-full"></div>)
            }
        </div>
    </AnimatePresence>);

}
export default Avatar;