import React from "react"
import chatCss from "./chat.module.css"
import {useRef, useEffect, useState } from "react"
import ChatElement from "./ChatElement.tsx"
import { useContextSelector } from "use-context-selector"
import { axiosPrivate } from "../../hooks/axios.js"
import { AppContext } from "../../AppContext.js"
import getChatInfo from "../../hooks/getChatInfo.tsx"
import audio_notification from "../../audio/notification_sound.mp3"
import e from "cors"


function Chats(props){
    const [chats_info, setChatsInfo] = useState([])
    const [showChats, setShowChats] = useState([]);
    const setHasToken = props.setHasToken;
    const selected_chat = props.selected_chat;
    console.log("SELECTED CHAT", selected_chat);
    const addNotifyCallback = useContextSelector(
        AppContext,
        (state) => state.addNotifyCallback
    );
    const removeNotifyCallback = useContextSelector(
        AppContext,
        (state) => state.removeNotifyCallback
    );



    const comparatorChatsInfo = ((a: any, b: any) => {
        if (!b?.last_message?.timestamp) {
            return -1;
        }
        if (!a?.last_message?.timestamp) {
            return 1;
        }
        return (new Date(b.last_message.timestamp)).getTime() - (new Date(a.last_message.timestamp)).getTime();
    });
    

    const addNewChat = (data) => {
        const chat = data;
        chat.user_photo = process.env.REACT_APP_BACKEND_URL + "/" + chat.photo_path;
        chat.first_name = chat.first_name;
        chat.last_name = chat.last_name;
        console.log(chat);
        setChatsInfo(cur_chats => {
            let new_chats = [chat, ...cur_chats];
            new_chats.sort(comparatorChatsInfo);

            return new_chats;
        });
        console.log("NEW CHATS", chats_info);
    };

    const getCallBack =  (theOpponent)=>{
        return ()=>{
            theOpponent.n_unread_messages = 0;
            props.setSelectedChat(theOpponent)
        };
    };

    const updateLastMessage = (data) => {
        if (data.should_notify) {
            const audio = new Audio(audio_notification);
            audio.play().catch(e => {
                console.log("error while playing audio " + e?.message);
            });
        }
        setChatsInfo(cur_chats => {
            let new_chats = cur_chats.map((chat) => {
                if(chat.chat_id == data.chat_id){
                    chat.last_message = data.message;
                    if (data.should_notify) {
                        console.log(chat.chat_id, selected_chat?.chat_id);
                        if (chat.chat_id != selected_chat?.chat_id) {
                            chat.n_unread_messages = chat.n_unread_messages + 1;
                        }
                    }
                }
                return chat;
            });
            new_chats.sort(comparatorChatsInfo);
            return new_chats;
        });
    };

    const updateChatInfo = (data) => {
        setChatsInfo(cur_chats => {
            let new_chats = cur_chats.map((chat) => {
                console.log(chat.chat_id);
                if (chat.chat_id == data.chat_id) {
                    chat = {...chat, ...data};
                    console.log(chat);
                }
                return chat;
            });
            new_chats.sort(comparatorChatsInfo);
            return new_chats;
        });
    };

    useEffect(()=>{
        addNotifyCallback('new_message', updateLastMessage);
        return ()=>{
            removeNotifyCallback('new_message', updateLastMessage);
        }
    }, [selected_chat]);

    useEffect(()=>{
        addNotifyCallback('new_chat', addNewChat);
        return ()=>{
            removeNotifyCallback('new_chat', addNewChat);
        }
    }, []);

    useEffect(()=>{
        let toDisplay = [];
        for(let i = 0; i < chats_info.length; ++i){
            toDisplay.push(
                <ChatElement key = {i} user={chats_info[i]} lastMessage={chats_info[i]?.last_message} callBack={getCallBack(chats_info[i])} is_chosen = {chats_info[i].chat_id == selected_chat?.chat_id}/>
            );
        }
        // const user_info = {name: "Global Chat", photo: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg", chat_id: "global"}
        // toDisplay.push(
        //     <ChatElement key = {chats_info.length} user={user_info} lastMessage={"Global Chaadadadadadadadadadadadadadadt"} callBack={getCallBack(user_info)} is_chosen = {selected_chat?.chat_id == "global"}/>
        // );
        setShowChats(toDisplay);
    }, [chats_info, selected_chat]);

    useEffect(()=>{
        if(props.platform) {
            setChatsInfo([]);
            props.setSelectedChat(null);
            axiosPrivate.get(`/get-chats/${String(props.platform)}`).then(response => {
                setHasToken(response.data?.has_token);
                if (response.data?.has_token) {
                    const chatsToShow = response.data.chats;
                    for (let i = 0; i < chatsToShow.length; i++) {
                        chatsToShow[i].user_photo = process.env.REACT_APP_BACKEND_URL + "/" + chatsToShow[i].metadata.photo_path;
                        chatsToShow[i].last_message = chatsToShow[i].last_message || {content : "No messages yet"};
                        chatsToShow[i].first_name = chatsToShow[i].metadata.first_name;
                        chatsToShow[i].last_name = chatsToShow[i].metadata.last_name;
                        console.log(chatsToShow[i]);
                    }
                    setChatsInfo(chatsToShow);    
                    for (let i = 0; i < chatsToShow.length; i++) {
                        getChatInfo(chatsToShow[i].chat_id, props.platform).then(response => {
                            console.log(response);
                            updateChatInfo(response.data);
                        }).catch(e => {
                            console.log("error while get-messages " + e?.message);
                        });
                    }  
                }
            }).catch(e => {
                if (e.response?.status == 400) {
                    console.log("No token found for the user");
                } else {
                    console.log("error while get-messages " + e?.message);
                }
            });
        }   
    }, [props.platform]);



    return (
        <div  className = "flex flex-col w-full h-full bg-white border-black border overflow-y-none" >
                {  showChats.length == 0 ? (<div className="flex justify-center w-full h-full pt-[35vh]"> <p className="text-5xl font-bold"> Empty </p> </div>) : 
                (<div className = "h-full w-full flex flex-col">
                    {showChats}
                </div>)}
            
            </div>
       );
}

export default Chats;