import React, { useState } from 'react';

const ChangeTemplate = ({ templates = ['Basic', 'Offers', 'Coach'], onSelect, className = ''}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (template) => {
    onSelect(template);
    setIsOpen(false);
  };

  return (
    <div className={`relative inline-block w-[200px] ${className}`}>
      <button
        className="flex w-full items-center bg-black text-white font-bold py-2 px-4 rounded-lg shadow-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
        onClick={() => setIsOpen(!isOpen)}
      >
        Select Template
        <svg
          className="ml-auto h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06-.02L10 10.92l3.71-3.73a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0l-4.25-4.25a.75.75 0 01-.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {templates.map((template, index) => (
              <button
                key={index}
                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => handleSelect(template)}
              >
                {template}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangeTemplate;
