import React from 'react';
import brain_logo from '../img/brains_logo.svg';
import chat_logo from '../img/chat_logo.svg';
import help_logo from '../img/help_logo.svg';
import home_logo from '../img/home_logo.svg';
import profile_logo from '../img/profile_logo.svg';
import settings_logo from '../img/settings_logo.svg';
import stats_logo from '../img/stats_logo.svg';
import templates_logo from '../img/templates_logo.svg';

const LogoByName = {
    'Home': home_logo,
    'Live Chat': chat_logo,
    'Stats': stats_logo,
    'Templates': templates_logo,
    'Chatbot Brains': brain_logo,
    'My Profile': profile_logo,
    'Settings': settings_logo,
    'Help': help_logo,
};

function PanelOption(props) {
    const is_active = props?.is_active;
    return  ( <div className = {props.className}>
                    <button className={`w-[80%] h-15 flex items-end p-3 ${is_active ? "bg-black" : "bg-white"} rounded-[14px] hover:bg-gray-500 focus:outline-none`} onClick = {props.onClick}>
                        <img src={LogoByName[props.name]} alt={props.name} className={`${is_active ? "filter invert grayscale" : ""} w-6 h-6 text-white`} />
                        <span className={`${!is_active ? "text-black" : "text-white"} font-display ml-3 font-semibold`}>{props.name}</span>
                    </button>
                </div>
            );
}


export default PanelOption;