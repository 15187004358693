import React, {useState, useRef, useEffect} from 'react';
import SideBar from './SideBar.tsx'
import IconButton from './IconButton.tsx';
import global_channel_logo from '../img/global_channel_logo.svg';
import instagram_channel_logo from '../img/instagram_channel_logo.svg';
import telegram_channel_logo from '../img/telegram_channel_logo.svg';
import whatsapp_channel_logo from '../img/whatsapp_channel_logo.svg';
import messenger_channel_logo from '../img/messenger_channel_logo.svg';
import widget_channel_logo from '../img/widget_channel_logo.svg';
import installed_logo from '../img/installed_logo.svg';
import setup_logo from '../img/setup_logo.svg';
import warning_logo from '../img/warning_logo.svg';
import updateUserToken, {updateUserTokenPreserveLink} from '../hooks/updateUserToken.js';
import { toast, ToastContainer } from 'react-toastify';
import getUserToken from '../hooks/getUserToken.js';
import { error_notification, success_notification } from '../hooks/utils.tsx';
import CopyCodeSnippet from './CopySnippet.tsx';
import {axiosPrivate, getLocalStorageToken} from '../hooks/axios.js';
import upload_file_logo from '../img/upload_file_logo.svg';  
import eye_logo from '../img/eye_logo.svg';
import { ReactComponent as ChatWidgetButton1 } from '../img/chatwidget_button_1.svg';
import { ReactComponent as ChatWidgetButton2 } from '../img/chatwidget_button_2.svg';

function ChannelsList(props) {
    const channels = ['Instagram', 'Telegram', 'WhatsApp', 'Messenger', 'Widget'];
    const channel_icons = [instagram_channel_logo, telegram_channel_logo, whatsapp_channel_logo, messenger_channel_logo, widget_channel_logo];
    const active_channel = props.active_channel;
    const set_active_channel = props.set_active_channel;
    return (<div className="flex flex-col ml-10 mt-10 w-[15vw]">
        <span className="text-3xl font-bold ml-5">Channels</span>
        {channels.map((channel, index) => {
            return <IconButton key = {index} className = {`mt-5 w-full hover:bg-gray-500`} image_className = {active_channel == channels[index] ? "filter invert grayscale": ""} name={channel} icon_url={channel_icons[index]} onClick = {() => {set_active_channel(prev_active_channel => channel)}} 
                    background_color = {channels[index] == active_channel ? 'bg-black' : 'bg-white'}
                    text_className = {channels[index] == active_channel ? 'text-white' : 'text-black'} />;
        })}
    </div>);
}

function LoadChannelToken(props) {
    const active_channel = props.active_channel;
    const loadingMessages = ['Loading.', 'Loading..', 'Loading...'];
    const [token, setToken] = useState('');
    const [currentMessage, setCurrentMessage] = useState(0);
    const [metadata, setMetadata] = useState({});
    const [setting_up, setSettingUp] = useState('');
    const [apiUrl, setApiUrl] = useState('');
    const [meta_business_id, setMetaBusinessId] = useState('');
    const [app_id, setAppID] = useState('');
    useEffect(() => {
        
        getUserToken(active_channel).then((result) => {
            if (result?.data?.token == undefined) {
                setToken('');
            } else {
                setToken(result.data.token);
            }
            console.log("result", result.data.api_url_link)
            setApiUrl(result.data.api_url_link)
            setMetaBusinessId(result.data.metadata?.business_id)
            setAppID(result.data.metadata?.app_id)
            setMetadata(result.data.metadata);
            console.log("metadata", result.data.metadata );
            console.log(token);
        }).catch((error) => {
            console.log("ERROR while getting token", error)
            setToken('');
        });
    }, [active_channel]);

   
    // Loading animation
    useEffect(() => {
        if (token != null) {
            return;
        }

        const intervalId = setInterval(() => {
            setCurrentMessage((prev) => (prev + 1) % loadingMessages.length);
        }, 400);

        return () => clearInterval(intervalId);
    }, [token]);

    const handleConnectClick = () => {
        setSettingUp(active_channel);
    };
    console.log(setting_up, active_channel);

    if (setting_up != active_channel) {
        if (setting_up != '') {
            setSettingUp('');
        }
        if (token == null) {
            return (<div className="flex flex-col text-center h-full w-full pt-[15vh]">
                <span className="text-5xl font-bold ml-5">Token for {active_channel}</span>
                <span className="text-4xl font-semibold ml-5 mt-[5vh]">{loadingMessages[currentMessage]}</span>
            </div>);
        } else {
            if (token == '') { // there is no token
                return (<div className="flex flex-col text-center items-center h-full w-full">
                        <img src = {setup_logo} alt="setup" className="w-[20vw] h-[30vh] mt-[10vh]" />
                        <span className="text-4xl font-bold mt-5">Establish customer relationships on {active_channel}</span>
                        <span className="text-2xl text-gray-600 mt-5 mx-[10vw]">
                            Build powerful automations, start a new campaign and look for the best practices
                            to reach a large {active_channel} audience. Start meaningful conversations with your customers right now.
                        </span>
                        <button className="mt-[5vh] bg-black text-white px-5 py-2 rounded-lg hover:bg-gray-600" onClick = {handleConnectClick}>Connect {active_channel}</button>
                </div>);
            } else {
                return (<div className="flex flex-col items-center text-center h-full pt-[15vh] w-full">
                    <img src={installed_logo} alt="installed" className="min-w-[8vw] min-h-[8vh]" />
                    <span className="text-3xl font-bold mt-5">You have already set up the bot <br/> <br/> {token.slice(0, 20)+"..."}</span>
                    <button onClick = {(e)=>{handleConnectClick()}} className = "bg-black w-15 text-white px-5 py-2 rounded-lg hover:bg-gray-600 mt-5">Change setup info</button>
                </div>);
            }
        }
    } else {
        if (active_channel == "Telegram") { // Telegram setup
            return <SetupTelegram setToken = {setToken} setSettingUp = {setSettingUp}/>
        } else if (active_channel == "Widget") { // Widget setup
            return <ChatWidgetSettings metadata = {metadata} setSettingUp = {setSettingUp}/>
        } else if (active_channel == "WhatsApp") {
                return <SetupWhatsapp active_channel = {active_channel} business_id={meta_business_id} token={token} apiUrl={apiUrl} setToken = {setToken} setMetaBusinessId={setMetaBusinessId} setSettingUp = {setSettingUp}/>
        } else if (active_channel == 'Messenger') {
            return <SetUpMessenger active_channel = {active_channel} business_id={meta_business_id} token={token} apiUrl={apiUrl} app_id = {app_id} setToken = {setToken} setMetaBusinessId={setMetaBusinessId} setAppID = {setAppID} setSettingUp = {setSettingUp}/>
        } else { 
            return <NotImplemeted channel = {active_channel} />
        }
    }
}

function NotImplemeted(props) {
    return (<div className="flex flex-col items-center text-center h-full pt-[30vh] w-full">
        <span className="text-5xl font-bold">Not implemented yet</span>
        <span className = "text-3xl font-bold mt-8">{props.channel} will be implemened in near future.</span>
        <span className = "text-3xl font-bold mt-8 text-gray-400">Comeback later!</span>
        </div>);
}

function generateCode(bot_name: string, location: string, theme_color: string, button_type: string, button_text: string | null, button_url: string | null, avatar_url: string | null) {
    // button_type is text, option-1, option-2, option-3
    // location is object in which code will be added. for widget it will be 
    return `
    <!-- Start of agentify (www.agentify.com) code -->
    <script type="text/javascript">
        window.__be = window.__be || {};
        window.__be.id = '${getLocalStorageToken()}';
        window.__be.themeColor = '${theme_color}';
        window.__be.location = ${location};
        window.__be.buttonType = '${button_type}';
        window.__be.buttonUrl = ${button_url};
        window.__be.buttonText = '${button_text}';
        window.__be.avatarUrl = ${avatar_url};
        window.__be.botName = '${bot_name}';
        window.__be.serverUrl = '${process.env.REACT_APP_BACKEND_URL}';
        (function() {
            console.log('loading agentify');
            var be = document.createElement('script'); be.type = 'text/javascript'; be.async = true;
            be.src = '${process.env.REACT_APP_BACKEND_URL}/chatbot_widget/generator.js';
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(be, s);
        })();
    </script>
    <noscript>You need to enable JavaScript in order to use the AI chatbot tool powered by Agentify</noscript>
    <!-- End of Agentify code -->
    `;
}

function UploadFileIcon(props) {
    return <img src={upload_file_logo} alt={props.name} className={`${props.className}`} />;    
}

function FileInputArea({id, handleFileChange, url, ...props}) {
    const [errorMessage, setErrorMessage] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [dragging, setDragging] = useState(false);
    const file_input_ref = useRef(null);

        
    
    const handleDragEnter = (e) => {
        if (!isButtonDisabled) {
            e.preventDefault();
            e.stopPropagation();
            setDragging(true);
        }
    };
    
    const handleDragOver = (e) => {
        if (!isButtonDisabled) {
            e.preventDefault();
            e.stopPropagation();
            setDragging(true);
        }
    };
    
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };
    
    const handleDrop = (e) => {
        if (!isButtonDisabled) {
            e.preventDefault();
            e.stopPropagation();
            setDragging(false);
            e.target = e.dataTransfer;
            handleFileChange(e);   
        } 
    };
    
    const handleInputClick = (event) => {
        if (event.target?.type != 'file' && event.target?.id != "file-input-label") {
            file_input_ref.current.click();
        }
    };


    return (
            <div className={`flex cursor-pointer flex-col justify-center items-center  border-2 w-[100%] border-gray-100 rounded-[1vw] p-4 ${dragging ? 'bg-gray-100' : ''}`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
                onClick = {handleInputClick}>
                {url ? null : <UploadFileIcon name="Upload File" className = "w-[2vw]"/>}
                <input
                    type="file"
                    className="hidden"
                    id={id}
                    onChange={handleFileChange}
                    accept=".jpg,.jpeg,.png,.svg"
                    disabled={isButtonDisabled}
                    ref={file_input_ref}
                />
                {
                    url ? 
                    <img src = {url} alt = "Uploaded file" className = "w-40 h-40"/>:
                    <label
                        htmlFor={id}
                        className={`cursor-pointer px-4 py-2  text-md text-center text-gray-400 ${
                        isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`} id="file-input-label">
                        Drag and drop or browse
                    </label>  
                }
            </div>);
}

const ColoredSvg = ({ button_id, color, className }) => {
    const svgRef = useRef(null);

    useEffect(() => {
      if (svgRef.current) {
        const svgElements = svgRef.current.querySelectorAll('path');
        svgElements.forEach(element => {
          element.setAttribute('fill', color);
          if (element.id == 'path_to_change_width') {
            element.setAttribute('width', '4.0rem');
            element.setAttribute('height', '4.0rem');
          }
        });
      }
    }, [color]);
    
    
    if (button_id == 0) {
        return <ChatWidgetButton1 className = {className} ref = {svgRef}/>;
    } else {
        return <ChatWidgetButton2 className = {className} ref = {svgRef}/>;
    }
}    

const ChatWidgetSettings = ({metadata, setSettingUp}) => {
    console.log(metadata);
    const metaOrDefault = (key, value) => { 
        return metadata[key] ? metadata[key] : value;
    };
    const [theme_color, setThemeColor] = useState(metaOrDefault("theme_color",  '#3B82F6'));
    const [chosen_button_color, setChosenButtonColor] = useState(metaOrDefault("chosen_button_color",  0));
    const [darkMode, setDarkMode] = useState(metaOrDefault("darkMode",  false));
    const color_input_ref = useRef(null);
    const [chosen_button_type, setChosenButtonType] = useState(metaOrDefault("chosen_button_type",  'button-image-type'));
    const [text_button, setTextButton] = useState(metaOrDefault("text_button", 'Chat with us!'));
    const [chosen_button_image, setChosenButtonImage] = useState(metaOrDefault("chosen_button_image", 0));
    const button_image_ref = useRef(null);
    const [bot_name, setBotName] = useState(metaOrDefault("bot_name", 'Agentify Chatbot'));
    const [should_save, setShouldSave] = useState(false);

    const [chosen_live_type, setChosenLiveType] = useState('Minimized');
    
    const handleColorPickerClick = () => {
        if (color_input_ref.current) {
            color_input_ref.current.click();
        }
    };
    const [input_images, setInputImages] = useState(
        metaOrDefault("input_images", {
        'avatar' : null,
        'background' : null,
        'button' : null
    }));

   
    const color_buttons = [{ tailwind_color: 'bg-blue-500', hex_color: '#3B82F6' }, {tailwind_color: 'bg-gray-300', hex_color: '#D1D5DB'},
                            { tailwind_color: 'bg-yellow-500', hex_color: '#F59E0B' }, { tailwind_color: 'bg-gradient-to-r from-purple-400 via-pink-500 to-red-500', hex_color: null }]

                        
                     
    const handleTextButtonChange = (e) => {
        setTextButton(e.target.value);
    }

    const handleButtonImageClick = () => {
        if (button_image_ref.current) {
            button_image_ref.current.click();
        }
    }

    const handleButtonImage = (id: number) => {
        setChosenButtonImage(id);
        setChosenLiveType('Minimized');
    };

    const allowedFiles = ['image/png', 'image/jpeg', 'image/svg+xml'];
    
    const handleFileChange = async (e) => {          
        for (let i = 0; i < e.target.files.length; i++) {
            if (!allowedFiles.includes(e.target.files[i].type)) {
                error_notification('Only images are allowed');
            }
        }
        if (e.target.files.length == 0) {
            return;
        }
        if (e.target.files.length > 1) {
            error_notification('Only one image is allowed');
            return;
        }
        const formData = new FormData();
        const fileName = "widget_" + getLocalStorageToken() + "_" + e.target.id + (e.target.files[0].type == 'image/svg+xml' ? '.svg' : (e.target.files[0].type == 'image/png' ? '.png' : '.jpeg'));
        formData.append('file', e.target.files[0], fileName);
        // console.log(fileName);
        const notIndexed = (await axiosPrivate.post('/upload-widget-files', formData)).data;
        console.log(notIndexed)
        setInputImages(prev => {
            return {...prev, [e.target.id]: process.env.REACT_APP_BACKEND_URL + "/" + fileName};
        }); 
        if (e.target.id == 'button') {
            handleButtonImage(2);
        }
        if (e.target.id == 'avatar') {
            setChosenLiveType('Chat');
        }
    };

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, [chosen_live_type]);

    useEffect(() => {
        let live_widget_options = document.querySelectorAll(".live-widget-option");

    
        live_widget_options.forEach((option) => {
            if (option.id == chosen_live_type) {
                option.querySelector('span').classList.add('w-full');
            } else {
                option.querySelector('span').classList.remove('w-full');
            }
        });
    }, [chosen_live_type]);

 
    const handleLiveOptionClick = (e) => {
        if (e.target.id == 'Live preview') {    
            alert('Live preview');
        } else {
            setChosenLiveType(e.target.id);
        }
    }

    const handleChosenMinimizedWindow = (button_type) => {
        setChosenButtonType(button_type);
        setChosenLiveType('Minimized');
    };

    useEffect(() => {
        window.__be = window.__be || {};
        window.__be.id = getLocalStorageToken();
        window.__be.themeColor = theme_color;
        window.__be.location = document.querySelector("#chat_insert");
        window.__be.buttonType =  (chosen_button_type == 'button-image-type' ? "option-" + (chosen_button_image + 1) : "text");
        window.__be.buttonUrl = input_images['button'];
        window.__be.buttonText = text_button;
        window.__be.avatarUrl = input_images['avatar'];
        window.__be.botName = bot_name;
        window.__be.serverUrl = process.env.REACT_APP_BACKEND_URL;
        let script = null;
        if (chosen_live_type == 'Minimized') {
            script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = false;
            script.src = `${process.env.REACT_APP_BACKEND_URL}/chatbot_widget/generator_logo.js`;
            document.getElementsByTagName('head')[0].appendChild(script);
        } else if (chosen_live_type == 'Chat') {
            script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = false;
            script.src = `${process.env.REACT_APP_BACKEND_URL}/chatbot_widget/generator_chat.js`;
            document.getElementsByTagName('head')[0].appendChild(script);
        }

        return () => {
            if (script != null) {
                script.remove();
            }
            if (document.querySelector("#chat_insert") != null) {
                document.querySelector("#chat_insert").innerHTML = '';
            }
        };
    }, [theme_color, bot_name, chosen_button_type, chosen_button_image, chosen_live_type, text_button, input_images['button'], input_images['avatar']]);
    
    const handleSave = () => {
        updateUserTokenPreserveLink(getLocalStorageToken(), 'Widget', {
            theme_color: theme_color,
            bot_name: bot_name,
            dark_mode: darkMode,
            chosen_button_color: chosen_button_color,
            chosen_button_type: chosen_button_type,
            chosen_button_image: chosen_button_image,
            text_button: text_button,
            input_images: input_images
        }).then((response) => {
            success_notification('Settings saved successfully');
            setShouldSave(true);
        }).catch((error) => {
            error_notification('Error while saving');
            console.log("Error while saving", error);
        });
    };

    const handleCancel = () => {
        setSettingUp('');
    };

    if (should_save) {
        return <SetupWidget theme_color={theme_color} bot_name={bot_name} darkMode={darkMode} chosen_button_type={(chosen_button_type == 'button-image-type' ? "option-" + (chosen_button_image + 1) : "text")} chosen_button_image={chosen_button_image} text_button={text_button} input_images={input_images} setSettingUp={setSettingUp} />;
    }

    return (
      <div className="flex flex-row w-full h-full">
        <div className="flex-1 flex flex-col bg-white  w-1/2 px-[2vw] pb-10">
            <h2 className="text-2xl font-semibold mb-4">Chat Widget settings</h2>
                <div className = "flex flex-col">
                    <div className="text-md text-xl mb-2">Chatbot name</div>
                    <input type = "text" className = "appearance-none rounded-lg w-full py-3 px-4 text-md border" value = {bot_name} onChange = {(e) =>{setBotName(e.target.value)}}/>
                </div>
                <div className="text-lg mt-3 mb-1">Theme color</div>
                <div className="flex space-x-3 mb-4">
                    {color_buttons.map((color_button, index) => {
                        return <div className = {`flex rounded p-1 border-2 ${chosen_button_color == index ? '  border-blue-300 transition-all duration-500' : 'border-transparent'} flex-col justify-center items-center`}>

                        {color_button.hex_color ?  
                            <div className={`w-10 h-10 p-1 rounded cursor-pointer ${color_button.tailwind_color}`} onClick={() => {
                                setThemeColor(color_button.hex_color);
                                setChosenButtonColor(index);
                            }}/>
                        :
                            <div className={`w-10 h-10 p-1 rounded cursor-pointer ${color_button.tailwind_color}`} onClick={() => {
                                setChosenButtonColor(index);
                            }}/>
                        }
                        </div>
                    })}
                </div>
            
            {chosen_button_color == 3 ? 
            <div className = "flex flex-row">
                <div className = "flex flex-col">
                    <h3 className="mb-2 text-lg">Main color</h3>
                    <div className="flex items-center cursor-pointer transition-opacity duration-500 hover:opacity-80" onClick={handleColorPickerClick}>
                        <div className="w-24 p-1 h-8 border rounded text-gray-700 ">
                            {theme_color}
                        </div>
                        <div className = "flex flex-row items-center border h-8 pt-[0.2vh] pr-1">
                            <input
                                type="color"
                                ref = {color_input_ref}
                                value = {theme_color}
                                onChange = {(e) => setThemeColor(e.target.value)}
                                className="w-6 h-7 border-none bg-white cursor-pointer"
                            />
                            <div className="ml-3 w-0 h-0 border-l-4 border-r-4 border-t-4 border-transparent border-t-black"></div>
                        </div>
                    </div>
                </div>
                {/* <div className="flex flex-col ml-12">
                    <h3 className="text-md text-lg mr-2">Dark mode</h3>
                    <label className="relative inline-block w-10 mr-2 align-middle select-none mt-2">
                        <input
                            type="checkbox"
                            checked={darkMode}
                            onChange={(e) => setDarkMode(e.target.checked)}
                            className="absolute opacity-0 w-0 h-0"
                        />
                        <span className={`block relative overflow-hidden h-6 w-14 rounded-full ${darkMode ? 'bg-green-500' : 'bg-gray-300' } cursor-pointer `}>
                            <span className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition transform ${darkMode ? 'translate-x-8 ' : ''}`}></span>
                            <span className = {` text-white absolute ${darkMode ? 'left-1 ': 'right-1'}`}>{darkMode ? 'ON' : 'OFF'}</span>
                        </span>
                    </label>
                </div> */}
            </div>
            : null}

            <div className = "flex flex-row w-full mt-4">
                <div className = "flex flex-col">
                    <span className = "text-lg mb-2 text-black">
                        Avatar
                    </span>
                    <FileInputArea id = {"avatar"} url = {input_images['avatar']} handleFileChange = {handleFileChange} />
                </div>
                {/* <div className = "flex flex-col ml-5">
                    <span className = "text-lg text-black">
                        Background
                    </span>
                    <FileInputArea id={'background'} />
                </div> */}
            </div>

            <div className = "mt-3">
                <h3 className="text-md text-lg mb-2">Minimized window</h3>
                <div className="flex flex-row">
                <div className={`w-[43.5%] h-[10vh] border rounded-lg cursor-pointer flex flex-col justify-center items-center ${chosen_button_type!='button-image-type' ? 'border-gray-200 hover:border-blue-300' : 'border-blue-500'} ml-5`}
                    onClick={() => {handleChosenMinimizedWindow('button-image-type')}} > 

                    
                    <ColoredSvg color = {theme_color} button_id={0} className = "h-16 w-16" />
                </div>
                <div className={`w-[43.5%] h-[10vh] border rounded-lg cursor-pointer flex flex-col justify-center items-center ${chosen_button_type!='button-text-type' ? 'border-gray-200 hover:border-blue-300' : 'border-blue-500'} ml-5`}
                    onClick={() => {handleChosenMinimizedWindow('button-text-type')}}> 
                    <div className = "rounded-t-lg bg-gray-200 px-2 py-1">
                        YOUR TEXT
                    </div>
                </div>
                </div>
                <div className = "flex flex-col items-center mt-8">
                    {chosen_button_type == 'button-image-type' ?
                    <div className = "w-full">
                        <h3 className="text-md text-lg mb-2">Minimized chat image</h3>
                        <div className = "flex flex-row w-full">
                            <div className={`w-[30%] h-[10vh] border rounded-lg cursor-pointer flex flex-col justify-center items-center ${chosen_button_image!=0 ? 'border-gray-200 hover:border-blue-300' : 'border-blue-500'} ml-5`}
                                onClick={() => {handleButtonImage(0)}}>
                                <ColoredSvg color = {theme_color} button_id={0} className = "h-12 w-12" />
                            </div>
                            <div className={`w-[30%] h-[10vh] border rounded-lg cursor-pointer flex flex-col justify-center items-center ${chosen_button_image!=1 ? 'border-gray-200 hover:border-blue-300' : 'border-blue-500'} ml-5`}
                                onClick={() => {handleButtonImage(1)}}>
                                <ColoredSvg color = {theme_color} button_id={1} className = "h-12 w-12" />
                            </div>
                            <div className={`w-[30%] h-[10vh] border-2 border-dashed rounded-lg cursor-pointer flex flex-col justify-center items-center ${chosen_button_image!=2 ? 'border-gray-200 hover:border-blue-300' : 'border-blue-500'} ml-5`} 
                                onClick={() => { handleButtonImageClick()}}>
                                <input type="file" className="hidden" id = {"button"} ref = {button_image_ref}  onChange={handleFileChange}/>
                                <img src = {(input_images['button'] == null ? upload_file_logo : input_images['button'])} alt = "Upload button image" className = "h-12 w-12"/>
                            </div>
                        </div> 
                    </div>

                    :  <input type="text" className={`appearance-none rounded-lg w-full py-3 px-4 text-lg border`} value={text_button} onChange={handleTextButtonChange}/>        
                    }
                </div>
            </div>
    
            <div className="mt-4 flex justify-end">
                <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick = {handleSave}>Save</button>
                <button className="ml-2 bg-gray-300 text-gray-800 px-4 py-2 rounded" onClick={handleCancel}>Cancel</button>
            </div>
      </div>

      {/* Live visualization */}
      <div className="flex flex-col pt-4 bg-white border-l border-r flex-1 border-gray-400 w-1/2">
        <ul className="flex flex-row h-10">
            <li className = "text-xl relative px-4 cursor-pointer live-widget-option" id = "Minimized"  onClick = {handleLiveOptionClick}>
                Minimized
                <span className="underline-text transition-all duration-100"></span>
            </li>
            <li className = "text-xl relative px-4 cursor-pointer live-widget-option" id ="Chat" onClick = {handleLiveOptionClick}>
                Chat
                <span className="underline-text transition-all duration-100"></span>
            </li>
            {/* <li className = "text-xl px-4 cursor-pointer flex flex-row ml-auto hover:opacity-80" id = 'Live preview' onClick = {handleLiveOptionClick}>
                <img src = {eye_logo} id="Live preview" alt = "Live preview" className = "w-6 mt-[-1vh] mr-2"/>
                Live preview
            </li> */}
        </ul>
        <div className = "bg-gray-300 py-1 px-[20%]">
            <div className="w-full flex justify-center items-ceter">
                <div 
                className="w-full border bg-white rounded-2xl px-2 text-center">
                    www.your-website.com 
                </div>
            </div>
        </div>
        <div className = "bg-gray-100 h-full w-full relative" id = "chat_insert">
        </div>
      </div>
    </div>);
  };
  

function SetupWidget({theme_color, bot_name, darkMode, chosen_button_type, chosen_button_image, text_button, input_images, setSettingUp}) {
    return ((<div className = "flex flex-col items-center w-full h-full pt-[10vh]">
        <div className = "flex flex-col items-start w-[25vw]">
            <span className = "text-xl font-bold"> How to install Chat Widget in your site </span>
            <div className = "flex flex-row rounded-3xl p-5 items-start mt-3 bg-gray-300 border-gray-400 border-2">
                    <img src = {warning_logo} alt = "Warning" className = "mt-2 w-6 "/>
                    <span className = "text-lg leading-0.5 ml-3">Do not share above code to third-parties, 
                                that can harm your business</span>
                </div>
                <span className = "text-xl mt-10">This instruction helps you to install Chat Widget with the bot.</span>
                <ul className = "flex flex-col mt-3">
                    <li className = "flex flex-row items-start w-full">
                        <div className = "rounded-full flex items-center justify-center bg-gray-400 w-8 h-8">
                            <p> 1 </p>
                        </div>
                        <span className = "ml-3 text-xl"> Copy this code and paste it before the closing <a className = "text-blue-500 cursor-pointer hover:underline"> &lt;/body&gt; </a>
                        tag on every page of your website</span>
                    </li>
                    <CopyCodeSnippet code = {generateCode(bot_name, "document.body", theme_color, chosen_button_type,
                        text_button, input_images['button'], input_images['avatar'])} />
                    <li className = "flex flex-row mt-6 items-start w-full">
                        <div className = "rounded-full text-center bg-gray-400 w-8 h-8">
                            <p> 2 </p>
                        </div>
                        <span className = "ml-3 text-xl"> Reload your website. The Chat Widget should appear in the bottom corner of a page.</span>
                    </li>
                </ul>
        </div>
    </div>));

}

function SetupTelegram(props) {
    const active_channel = "Telegram";
    const input_ref = useRef(null);
    const setToken = props.setToken;
    const setSettingUp = props.setSettingUp;
    const handleClick = () => {
        updateUserToken(input_ref.current.value, active_channel).then((result) => {
            success_notification('Successfully set!');
            setToken(input_ref.current.value);
            setSettingUp('');
        }).catch((error) => {
            console.log("error " , error);
            error_notification('Token is incorrect!');
        });
    };
    return (<div className = "flex flex-col items-center w-full h-full pt-[10vh]">
            <div className = "flex flex-col items-start w-[25vw]">
                <span className = "text-xl font-bold"> Connect existing Telegram bot</span>
                <div className = "flex flex-row rounded-3xl p-5 items-start mt-3 bg-gray-300 border-gray-400 border-2">
                    <img src = {warning_logo} alt = "Warning" className = "mt-2 w-6 "/>
                    <span className = "text-lg leading-0.5 ml-3">We highly recommend you not to use the same token for 
                        different services, otherwise the bot will work incorrectly.</span>
                </div>
                <span className = "text-xl mt-10">This instruction helps you to connect to the Telegram bot.</span>
                <ul className = "flex flex-col mt-3">
                    <li className = "flex flex-row items-start w-full">
                        <div className = "rounded-full flex items-center justify-center bg-gray-400 w-8 h-8">
                            <p> 1 </p>
                        </div>
                        <span className = "ml-3 text-xl"> Open <a href = "https://t.me/BotFather" className = "text-blue-500 cursor-pointer hover:underline"> @BotFather</a> in Telegram and click <span className = "font-bold">/start</span></span>
                    </li>
                    <li className = "flex flex-row mt-6 items-start w-full">
                        <div className = "rounded-full text-center bg-gray-400 w-8 h-8">
                            <p> 2 </p>
                        </div>
                        <span className = "ml-3 text-xl"> Send <span className = "font-bold">/mybots</span> and choose the bot you want to connect from the list</span>
                    </li>
                    <li className =  "flex flex-row mt-6 items-start">
                        <div className = "rounded-full flex items-center justify-center bg-gray-400 w-8 h-8">
                            <p> 3 </p>
                        </div>
                        <span className = "ml-3 text-xl"> Copy its API token and paste it here </span>
                    </li>
                </ul>
                <span className = "text-xl mt-8">Telegram bot token</span>
                <input ref = {input_ref} type = "text" className = "border-2 border-gray-500 rounded-lg w-full px-2 py-1" placeholder = "Enter token"/>
                <button onClick = {handleClick} className = "bg-black w-full text-white px-5 py-2 rounded-lg hover:bg-gray-600 mt-5">Connect Telegram</button>
            </div>
    </div>);
}


function SetupWhatsapp(props) {
    const active_channel = props.active_channel;
    const input_ref = useRef(null);
    const bus_id_ref = useRef(null);
    const setToken = props.setToken;
    const setSettingUp = props.setSettingUp;
    const setMetaBusinessId = props.setMetaBusinessId;
    const handleClick = () => {
        updateUserTokenPreserveLink(input_ref.current.value, active_channel, {business_id : bus_id_ref.current.value}).then((result) => {
            success_notification('Successfully set!');
            setToken(input_ref.current.value);
            setSettingUp('');
        }).catch((error) => {
            console.log("error", error);
            error_notification('Token is incorrect!');
        });
    };
    const copyToClipboard = () => {
        if (props.apiUrl) {
            navigator.clipboard.writeText(props.apiUrl)
                .then(() => {
                    console.log('Copied to clipboard');
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                });
        }
    };
    useEffect(()=>{
        if(input_ref && input_ref.current){
            input_ref.current.value = props.token;
        }
    } , [input_ref])
    useEffect(()=>{
        if(bus_id_ref && bus_id_ref.current){
            bus_id_ref.current.value = props.business_id;
        }
    } , [bus_id_ref])
    
    return (<div className = "flex flex-col items-center w-full h-full pt-[10vh]">
            <div className = "flex flex-col items-start w-[25vw]">
                <span className = "text-xl font-bold"> Connect {active_channel} </span>
                <div className = "flex flex-row rounded-3xl p-5 items-start mt-3 bg-gray-300 border-gray-400 border-2">
                    <img src = {warning_logo} alt = "Warning" className = "mt-2 w-6 "/>
                    <span className = "text-lg leading-0.5 ml-3">We highly recommend you not to use the same app for 
                        different services, otherwise the bot will work incorrectly. </span>
                </div>
                <span className = "text-xl mt-10">This instruction helps you to connect {active_channel} chatbot.</span>
                <ul className = "flex flex-col mt-3">
                    <li className = "flex flex-row items-start w-full">
                        <div className = "rounded-full flex items-center justify-center bg-gray-400 w-8 h-8">
                            <p> 1 </p>
                        </div>
                        <span className = "ml-3 text-xl"> Go to Meta developers <a href = "https://developers.facebook.com/" className = "text-blue-500 cursor-pointer hover:underline"> link</a>{ " create App-> Other -> Business. "}<span className = "font-bold">After this the app is created</span></span>
                    </li>
                    <li className = "flex flex-row mt-6 items-start w-full">
                        <div className = "rounded-full text-center bg-gray-400 w-8 h-8">
                            <p> 2 </p>
                        </div>
                        <span className = "ml-3 text-xl"> Copy this link <span className = "font-bold underline cursor-pointer" onClick={(e)=>{copyToClipboard();success_notification("Link copied to clipboard!")}} >{props.apiUrl ? props.apiUrl.slice(0,15)+"...(click)" : 'error_link'}</span> and put it inside webhook settings.</span>
                    </li>
                    <li className =  "flex flex-row mt-6 items-start">
                        <div className = "rounded-full flex items-center justify-center bg-gray-400 w-8 h-8">
                            <p> 3 </p>
                        </div>
                        <span className = "ml-3 text-xl">Generate permanent access token and provide it here </span>
                    </li>
                </ul>
                <span className = "text-xl mt-8">WhatsApp access token</span>
                <input ref = {input_ref} type = "text" className = "border-2 border-gray-500 rounded-lg w-full px-2 py-1" placeholder = "Enter access token"/>
           
                <span className = "text-xl mt-8">WhatsApp business id</span>
                <input ref = {bus_id_ref}  type = "text" className = "border-2 border-gray-500 rounded-lg w-full px-2 py-1" placeholder = "Enter business id"/>
           
                <button onClick = {handleClick} className = "bg-black w-full text-white px-5 py-2 rounded-lg hover:bg-gray-600 mt-5">Connect {active_channel}</button>
            </div>
    </div>);
}




function SetUpMessenger(props) {
    const active_channel = props.active_channel;
    const setToken = props.setToken;
    const setSettingUp = props.setSettingUp;
    const setMetaBusinessId = props.setMetaBusinessId;
    const setAppID = props.setAppID;
    const handleClick = () => {
        updateUserTokenPreserveLink(props.token, active_channel, {business_id : props.business_id, app_id: props.app_id}).then((result) => {
            success_notification('Successfully set!');
            setSettingUp('');
        }).catch((error) => {
            console.log("error", error);
            error_notification('Token is incorrect!');
        });
    };
    const copyToClipboard = () => {
        if (props.apiUrl) {
            navigator.clipboard.writeText(props.apiUrl)
                .then(() => {
                    console.log('Copied to clipboard');
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                });
        }
    };

    const handleBusinessChange = (e) => {
        setMetaBusinessId(e.target.value);
    };

    const handleTokenChange = (e) => {  
        setToken(e.target.value);
    };

    const handleAppIdChange = (e) => {
        setAppID(e.target.value);
    };


 
    console.log(props.business_id)
    console.log(props.apiUrl)
    console.log(active_channel)
    return (<div className = "flex flex-col items-center w-full h-full pt-[5vh]">
            <div className = "flex flex-col items-start w-[25vw]">
                <span className = "text-xl font-bold"> Connect {active_channel} </span>
                <span className = "text-xl mt-3">Follow the below instruction to connect {active_channel} chatbot.</span>
                <ul className = "flex flex-col mt-3">
                    <li className = "flex flex-row items-start w-full">
                        <div className = "rounded-full flex items-center justify-center bg-gray-400 w-8 h-8">
                            <p> 1 </p>
                        </div>
                        <span className = "ml-3 text-xl"> Go to Meta developers <a href = "https://developers.facebook.com/" className = "text-blue-500 cursor-pointer hover:underline"> link</a>{ " create App-> Other -> Business. "}<span className = "font-bold">After this the app is created. Add Messenger into your app.</span></span>
                    </li>
                    <li className = "flex flex-row mt-6 items-start w-full">
                        <div className = "rounded-full text-center bg-gray-400 w-8 h-8">
                            <p> 2 </p>
                        </div>
                        <span className = "ml-3 text-xl"> Copy this link <span className = "font-bold underline cursor-pointer" onClick={(e)=>{copyToClipboard();success_notification("Link copied to clipboard!")}} >{props.apiUrl ? props.apiUrl.slice(0,15)+"...(click)" : 'error_link'}</span> and put it inside webhook settings.</span>
                    </li>
                    <li className =  "flex flex-row mt-6 items-start">
                        <div className = "rounded-full flex items-center justify-center bg-gray-400 w-8 h-8">
                            <p> 3 </p>
                        </div>
                        <span className = "ml-3 text-xl">Generate permanent access token and provide it here </span>
                    </li>
                    <li className =  "flex flex-row mt-6 items-start">
                        <div className = "rounded-full flex items-center justify-center bg-gray-400 w-8 h-8">
                            <p> 4 </p>
                        </div>
                        <span className = "ml-3 text-xl">Provide App ID by clicking { "Settings -> Basic -> App ID "} </span>
                    </li>
                </ul>
                <span className = "text-xl mt-8">{active_channel} access token</span>
                <input value = {props.token} type = "text" className = "border-2 border-gray-500 rounded-lg w-full px-2 py-1" placeholder = "Enter access token" onChange ={handleTokenChange}/>
           
                <span className = "text-xl mt-8">{active_channel} page id</span>
                <input value={props.business_id} type = "text" className = "border-2 border-gray-500 rounded-lg w-full px-2 py-1" placeholder = "Enter business id" onChange={handleBusinessChange}/>
                <span className = "text-xl mt-8">{active_channel} App ID</span>
                <input value={props.app_id} type = "text" className = "border-2 border-gray-500 rounded-lg w-full px-2 py-1" placeholder = "Enter business id" onChange={handleAppIdChange}/>
           
                <button onClick = {handleClick} className = "bg-black w-full text-white px-5 py-2 rounded-lg hover:bg-gray-600 mt-5">Connect {active_channel}</button>
            </div>
    </div>);
}





function RightPanel(props) {
    const [active_channel, set_active_channel] = useState('Instagram');

    return (<div className={`flex flex-col w-[85%] h-[100vh]  ${props.className}`}>
        <span className="ml-[5vw] mt-10 text-5xl font-bold">Settings</span>
        <div className="ml-10 border-gray-100 border-b-2 mr-10 mt-4"></div>
        <div className="flex h-full">
            <ChannelsList active_channel = {active_channel} set_active_channel = {set_active_channel} />
            <div className="ml-6 child-line transform -translate-x-1/2 h-full w-0.5 bg-gray-100" />
            <LoadChannelToken active_channel = {active_channel} />
        </div>
    </div>);
}

function SettingsPage() {
    return (<div className = "flex ">
            <ToastContainer />
            <SideBar active_button="Settings" />
            <div className="child-line transform -translate-x-1/2 h-[100vh] w-0.5 bg-gray-100" />
            <RightPanel />
        </div>);
}

export default SettingsPage;