import axios from 'axios'
//require('./dotenv').config()
const BASE_URL = process.env.REACT_APP_BACKEND_URL;
console.log(typeof(BASE_URL));
console.log("the base url is " + BASE_URL);

export const axiosPublic = axios.create({
    baseURL : BASE_URL,
    withCredentials: true
});


export const axiosPrivate = axios.create({
    baseURL : BASE_URL,
    headers :  {'Content-Type' : 'application/json'},
    withCredentials : true 
});
// axiosPrivate.interceptors.request.use(
//     (config) => {
//       const token = localStorage.getItem('jwt_token'); // Retrieve the token from local storage or context
//       if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//       }
//       return config;
//     },
//     (error) => {
//       return Promise.reject(error);
//     }
//   );
axiosPrivate.interceptors.response.use(
    response => {
        // Do something with the response data
 
         return response;
    },
    error => {
        console.log(error.response);
        if (error.response && error.response.status === 403) {
            // Handle the 403 error here
            // For example, you could redirect to a login page or show an error message
            console.log('403 Forbidden Error:', error.response.data);
            window.location="/login";
        }
        // Handle response errors
        return Promise.reject(error);
    }
);

export const setJwtToken = (theToken) =>{
    localStorage.setItem("jwt_token", JSON.stringify(theToken));
}

export const getJwtToken = () =>{
    return JSON.parse(localStorage.getItem("jwt_token"));
}


export const getLocalStorageToken = () => {
    const storage_token = localStorage.getItem("jwt_token") && JSON.parse(localStorage.getItem('jwt_token'));
    return storage_token;
}

export const setAxiosHeader = () => {
    try{
        const storage_token = getLocalStorageToken();
        console.log(storage_token);
        if (storage_token != null) {
            console.log("SUCCESS ")
            axiosPrivate.defaults.headers['Authorization'] = `Bearer ${storage_token}`;
        }
    } catch (e) {
        console.log(e);
    }    
}

export const removeJwtToken = () =>{
    localStorage.removeItem("jwt_token");
    axiosPrivate.defaults.headers['Authorization'] = null;
}
  
setAxiosHeader();