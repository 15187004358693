import React from 'react';

export default function Template(props) {
    
    return (
        <div className = {`flex flex-col rounded-[2vw] px-[2vw] py-[5vh] cursor-pointer ${props.className} transition-colors duration-300 w-[25vw]`} onClick={props.onClick}>
            <span className="text-5xl font-bold text-white">{props.name}</span>
            <span className="text-2xl font-semibold text-white mt-7">{props.description}</span>
        </div>
    );
}

