import React from "react"
import chatCss from "./chat.module.css"
import Avatar from "./Avatar.tsx";

function OppMessage(props){
    return (<div className = {`${props.className} mt-3 `}>
                <div className="flex flex-row items-start float-left w-full">
                    {props.user? (<Avatar className = "w-8 h-8 mt-2" user_photo={props.user?.user_photo} />) : null}
                    <span className = "ml-3 bg-gray-200 rounded-lg px-3 py-2 font-bold text-lg break-all max-w-[75%]">
                       {props?.text}
                    </span>
                </div>
        </div>);
}
export default OppMessage;