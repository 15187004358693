import { axiosPublic, setJwtToken, axiosPrivate, removeJwtToken, setAxiosHeader } from "./axios";
import bcrypt from 'bcryptjs';

const getHashedPassword = async (password: string) => {
    const hashed_password = await bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u');
    return hashed_password;
}


export const registerAccount = async (name: string, email: string, password: string, receive_emails: boolean) => {
    const hashed_password: string = await getHashedPassword(password);
    return axiosPublic.post(`${process.env.REACT_APP_BACKEND_URL}/register-user`,
        {login: email, password: hashed_password, name: name, receive_emails: receive_emails}
    ).then(response => {
        console.log(response);
        setJwtToken(response.data.jwt_token);
        setAxiosHeader();
        return response.data.accessToken;
    });
}

export const loginAccount = async (email: string, password: string) => {
    const hashed_password = await getHashedPassword(password);  
    console.log(hashed_password, password); 
    return axiosPublic.post(`${process.env.REACT_APP_BACKEND_URL}/login`, { login: email, password: hashed_password }).then(response => {
        setJwtToken(response.data.jwt_token);
        setAxiosHeader();
        return response.data.jwt_token;
    });
}
export const changePassword = async (new_password: string) => {
    const hashed_password = await getHashedPassword(new_password);
    console.log(hashed_password, new_password);
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND_URL}/change-password`, { password: hashed_password });
}

export const logoutAccount = async () => {
    removeJwtToken();
}