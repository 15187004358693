import React, {useState, useRef, useEffect} from 'react';
import SideBar from './SideBar.tsx'
import our_logo from '../img/logo.svg';
import IconButton from './IconButton.tsx';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { toast, ToastContainer } from 'react-toastify';
import { registerAccount } from '../hooks/authorization.tsx';
import { setAxiosHeader } from '../hooks/axios.js';
import { InputField } from './InputField.tsx';

function RegistrationPage() {
    const name = useRef(null);
    const email = useRef(null);
    const password = useRef(null);
    const [is_password_visible, setPasswordVisibility] = useState(false);
    const [accept_emails, setAcceptEmails] = useState(true);
    const navigate = useNavigate();

    function toggleAcceptEmails() {
        setAcceptEmails(prev_accept => !prev_accept);
    }

    function handleSignIn() {
        navigate('/login/');
    }

    function handleRegister() {
        console.log(email.current.value);
        console.log(password.current.value);
        console.log(name.current.value);
        const is_valid_email = validator.isEmail(email.current.value);
        if (email.current.value == null || !is_valid_email) {
            toast.error('Incorrect email!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }
        if (password.current.value == null || password.current.value.length < 8) {
            toast.error('Password is too small!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }
        if (name.current.value == null) {
            toast.error('Name is empty!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }
        registerAccount(name.current.value, email.current.value, password.current.value, accept_emails).then((result) => {
            console.log("Successfully registered");
            navigate('/home/');
        }).catch((error) => {
            toast.error('User with this email already exists!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        });
    }

    return (
            <div className = "flex flex-row w-full h-full">
                <ToastContainer />
                <div className = "relative flex flex-col w-[30vw] h-full">
                    <img src={our_logo} alt="logo" className = " absolute right-[0vw] top-[15vh] w-[10vw] h-[20vh]"/>
                </div>
                <div className = "flex flex-col w-[45vw] h-full px-[5vw] pt-[10vh]">
                    <span className="text-5xl font-bold ">Welcome to Agentify</span>
                    <span className="text-xl text-gray-600 mt-5">
                        Our platform that empowers you to effortlessly create and manage AI bots for your social media. With just a few clicks, bring your online presence to life with intelligent, customizable agents that enhance engagement and streamline your online interactions. Register today to experience a new level of automation and convenience!
                    </span>
                    <InputField name = "Name" reff = {name} className = "w-full" type = "text" placeholder = "Enter your name" has_visibible_property = {false} />
                    <InputField name = "Email" reff = {email} className = "mt-7 w-full" type = "email" placeholder = "Enter your email" has_visibible_property = {false} />
                    <InputField name = "Password" reff = {password} className = "mt-7 w-full" type = "password" placeholder = "Enter your password"  has_visibible_property = {true} 
                                                is_visible = {is_password_visible} setVisibility = {setPasswordVisibility} />
                    <div className = "flex flex-row mt-10">
                        <button className = "    bg-black text-white text-2xl rounded-[1vw] h-[7vh] w-[14vw] hover:bg-gray-800 " onClick = {handleRegister}>
                            Register
                        </button>
                        <div className = "flex flex-row ml-5 items-start w-[20vw]">
                            <input
                                    type="checkbox"
                                    id="terms"
                                    checked={!accept_emails}
                                    onChange={toggleAcceptEmails}
                                    className="h-7 w-[5vw] border border-gray-300 rounded accent-black"
                            />
                            <label htmlFor="terms" className = "ml-2 text-lg">I don't want to receive emails about new Agentify products, best practices, or special offers.</label>
                        </div>
                    </div>
                    <div className = "flex flex-row mt-10">
                        <span className = "text-xl">Already have an account?</span>
                        <button className = "text-xl font-bold ml-2" onClick = {handleSignIn}>Sign In</button>
                    </div>
                </div>
            </div>
       );
}

export default RegistrationPage;