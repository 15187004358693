import React, {useState, useRef, useEffect} from 'react';
import SideBar from './SideBar.tsx'
import option_settings_logo from '../img/option_settings_logo.svg';
import option_settings_rename_logo from '../img/option_settings_rename_logo.svg';
import option_settings_duplicate_logo from '../img/option_settings_duplicate_logo.svg';
import option_settings_edit_logo from '../img/option_settings_edit_logo.svg';
import option_settings_remove_logo from '../img/option_settings_remove_logo.svg';
import { axiosPrivate } from '../hooks/axios.js';
import Loading from './Loading.tsx'
import { useNavigate } from 'react-router-dom';
import { error_notification, success_notification } from '../hooks/utils.tsx';
import { useContextSelector } from 'use-context-selector';
import { AppContext } from '../AppContext.js';
import { useLocation } from 'react-router-dom';
const OptionSingleSettings = (props) => {
    return (<div className="flex hover:bg-gray-200 cursor-pointer" onClick={props.onClick}>
            <img src = {props.icon_url} alt={props.name} className="w-[2vw] h-[2vw]"/>
            <span className={`${props.text_style} ml-3 text-2xl font-semibold`}>{props.name}</span>
        </div>);
}

const OptionSettingsList = ({callbacks}) => {
    const settings_list = ['Rename', 'Duplicate', 'Edit', 'Remove'];
    const settings_icon_list = [option_settings_rename_logo, option_settings_duplicate_logo, option_settings_edit_logo, option_settings_remove_logo];   
    const text_style_list = ['text-black', 'text-black', 'text-black', 'text-red-500'];
    console.log(" callbacks is " , callbacks)
    return (<div>
        {settings_list.map((setting, index) => {
            return <OptionSingleSettings onClick={(e)=>{callbacks[setting]();}} key = {index} name={setting} icon_url={settings_icon_list[index]} text_style = {text_style_list[index]} />;
        })}
    </div>);
}

const Switch = (props) => {
    const isOn = props.isOn;

    const toggleSwitch = (e) => {
      e.stopPropagation(); 
      e.preventDefault();
      props.onClick();
      
      // # TODO add logic of activation and deactivation of the brain.
    };
  
    return (
      <div className="flex items-center">
        {/* Switch container */}
        <div
          className={`w-[5vw] h-[5vh] flex items-center rounded-full p-1 cursor-pointer ${
            isOn ? 'bg-green-500' : 'bg-gray-300'
          }`}
          onClick={toggleSwitch}
        >
          {/* Switch handle */}
          <div
            className={`w-[2vw] h-[4vh] bg-white rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
              isOn ? 'translate-x-[2.5vw]' : 'translate-x-0'
            }`}
          />
        </div>
      </div>
    );
  };
  

function SingleOption(props) {
    const [is_visible, setIsVisible] = useState(false);

    const button_ref = useRef(null);
    const list_ref = useRef(null);

    const handleButtonClick = () => { 
        setIsVisible(prev_is_visible => true);  
    };

    const handleDocumentClick = (event) => {
        if (
        button_ref.current &&
        !button_ref.current.contains(event.target) &&
        list_ref.current &&
        !list_ref.current.contains(event.target)
        ) {
        setIsVisible(prev_is_visible => false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
        document.removeEventListener('click', handleDocumentClick);
        };
    }, []);


   

    const navigate = useNavigate();

    const callbacks = {
      "Remove" : ()=>{
          props.removeConfig(props.config.config_id);
      },
      "Edit" : ()=> {
          navigate(`/configure/${props.config.config_id}`);
      }
    }

    return (<div className={`flex items-center ${props.className} w-[60vw] items-center relative`} >
        <div className="flex w-[100%] px-[3vw] rounded-[1.5vw] py-[2vh] justify-between items-center bg-gray-100"  onClick={(e)=>{
      navigate(`/configure/${props.config.config_id}`)
    }}>
            <span className=" font-display text-4xl font-semibold">{props.config?.name}</span>
            <Switch onClick = {props.onClick} isOn = {props.is_active}/>
        </div>
        <button ref = {button_ref} onClick={handleButtonClick} className="w-[4vw] h-[4vw] flex items-center justify-center p-3 bg-gray-100 rounded-full hover:bg-gray-300 focus:outline-none ml-10">
            <img src={option_settings_logo} alt={props.config?.name} className='w-[2vw]'/>
        </button>
        {is_visible ? 
        <ul ref={list_ref} className={`absolute top-[4vh] right-[-8vw] bg-white border border-gray-300 rounded shadow-lg z-50 transition-opacity duration-300 opacity-0 ${is_visible ? 'opacity-100' : 'opacity-0'}`}>
            <OptionSettingsList callbacks={callbacks}/>
        </ul> : null}
    </div>);
}

function Options(props) {

    

    const [current_option, set_current_option] = useState(null);
    const [error, setError] = useState("");
    const [configs, setConfigs] = useState([]);
    const [loading , setLoading] = useState(true);
  
    useEffect(()=>{
      axiosPrivate.get(`/get-configs`).then(response => {
        console.log("configs are " , response.data.configs);
        setConfigs(response.data.configs);
        setLoading(false);
      }).catch(e => {
        error_notification("Error while loading configs: " + e?.message)
      });
      axiosPrivate.get('/get-active-config').then(response => {
        console.log("ACTIVE IS " , response.data.config_id)
        set_current_option(response.data.config_id);
      }).catch(e => {
        error_notification("Error while loading configs: " + e?.message)
      });
    }, [props.update]);
    
    const removeConfig = (config_id)=>{
      axiosPrivate.post('/remove-config', {config_id: config_id}).then(response => {
        console.log("Config removed");
        props.upd();
        success_notification("Config removed successfully")
      }).catch(e => {
        error_notification("Error while removing config, try again later.");
      });
    }

    const activateConfig = (config_id)=>{
      axiosPrivate.post('/activate-config', {config_id: config_id}).then(response => {
        console.log("Config activated");
        success_notification("Config activated successfully")
      }).catch(e => {
        error_notification("Error while activating config, try again later.");
      });
    }

    return (<div className={`${props.className} flex flex-col mt-10 w-[100%]`}>

          
                <Loading loading={loading} size="big" />
                
                {configs.map((config, index) => {
                  const onToggle = () => {
                    set_current_option(prev_current_option => {
                      if(prev_current_option == config.config_id){
                        return ""
                      }else{
                        return config.config_id
                      }
                    });
                    activateConfig(config.config_id);
                  }
                    return <SingleOption removeConfig={removeConfig} config={config} key = {index} is_active = {config.config_id == current_option} className = {`${index == 0 ?  "" : "mt-8"}`} onClick = {onToggle} />
                })}


                <div>
                  {error}
                </div>
            </div>);
}

function RightPanel() {
    const [update , setUpdate] = useState(false);
    const navigate = useNavigate();
    const createNewConfiguration = (template_type: string | null) => {
      template_type = template_type || 'basic';
      setShadowLoading("full");
      return axiosPrivate.post(`/create-config`, {
          schema : {
            type: template_type
          }
        }).then(response => {
        console.log("New configuration created");  
        setShadowLoading("");
        setUpdate(update => !update)
        return response.data;
      }).catch(e => {
        error_notification("Error while creating new configuration: ");
        return null;
      });
    }
    const setShadowLoading = useContextSelector(
      AppContext,
      (state) => state.setShadowLoading
  );
   const upd = ()=>{
    setUpdate(cur_update => !cur_update);
   }

   const location = useLocation();
   useEffect(()=>{
      if (location.state && location.state.template != null) {
        createNewConfiguration(location.state.template.type).then(config => {
          navigate(`/configure/${config.new_config.config_id}`);
        });
      }
    }, [location.state]);   
    return (<div className="flex flex-col w-[85%] h-[100vh]">
        <span className="ml-[5vw] mt-10 text-5xl font-bold">Versions</span>
        
        <div className="ml-10 border-gray-200 border-b-2 mr-10 mt-5"></div>
        
        

        <div className="pl-[5vw] pr-[5vw] w-[100%]">
          <div className="flex justify-end mt-5">
          <button className="flex w-[200px] items-center bg-black text-white font-bold py-2 px-4 rounded-lg shadow-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black" onClick={(e)=>{
              
              createNewConfiguration('basic');
          }}>
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path>
            </svg>
            New Configuration
          </button>

          </div>
          <Options update={update} upd={upd} />
        </div>
    </div>);
}

function BotBrainsPage() {
    return (<div className = "flex ">
            <SideBar active_button="Chatbot Brains" />
            <div className="child-line transform -translate-x-1/2 h-[100vh] w-0.5 bg-gray-200" />
            <RightPanel />
        </div>);
}

export default BotBrainsPage;