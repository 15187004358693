
import {axiosPrivate} from './axios.js';

export async function getBusinessInfo() {
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND_URL}/get-info`);
}

export async function updateBusinessInfo(name: string, email: string, receive_emails: boolean) {
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND_URL}/update-info`, {
        name,
        email,
        receive_emails
    });
}