import React, { useState } from 'react';
import PanelOption from './PanelOption.tsx';
import { useNavigate } from 'react-router-dom';
export default function PanelOptions(props) {
    const navigate = useNavigate();
    const [active_button, set_active_button] = useState(props?.active_button);

    return (<div className={props.className}> 
            <PanelOption name="Home" is_active={active_button == 'Home'} onClick = {() => {navigate('/home/')}} />
            <PanelOption className = 'mt-2' name="Stats" is_active={active_button == 'Stats'} onClick = {() => {navigate('/stats/')}}/>
            <PanelOption className = 'mt-2' name="Templates" is_active={active_button == 'Templates'} onClick = {() => {navigate('/templates/')}}/>
            <PanelOption className = 'mt-2' name="Chatbot Brains" is_active={active_button == 'Chatbot Brains'} onClick = {() => {navigate('/brains/')}}/>
            <PanelOption className = 'mt-2' name="Live Chat" is_active={active_button == 'Live Chat'} onClick = {() => {navigate('/chat/')}} />
            <PanelOption className = 'mt-2' name="Settings" is_active={active_button == 'Settings'} onClick = {() => {navigate('/settings/')}} />
        </div>);

}

