import React, {useState, useEffect, useRef} from 'react';
import SideBar from './SideBar.tsx'
import right_arrow from '../img/right_arrow.svg';
import Template from './Template.tsx';
import { useNavigate } from 'react-router-dom';
import getStatistic from '../hooks/getStatistic.tsx';
import {getBusinessInfo} from '../hooks/getBusinessInfo.tsx';
import { ToastContainer, toast } from 'react-toastify';
import SingleShadowedTemplateDescription from './SingleShadowedTemplateDescription.tsx';

function CircledButtonWithName(props) {
    return (<div className={`${props.className} flex flex-col items-center`}>
            <button className="w-[5vw] h-[5vw] rounded-full bg-gray-100 hover:bg-gray-300 text-white flex items-center justify-center" onClick = {props.onClick}>
                <img src={right_arrow} alt="arrow" className="w-[2.5vw]"/>
            </button>
            <span className="text-2xl font-semibold text-align mt-2">{props.name}</span>
        </div>
    );
}

function SingleStatistic(props) {
    const num = Number(props.value);
    let value = '';
    if (isNaN(num)) {
        value = props.value;
    } else {
        value = (Number.isInteger(num) ? num : num.toFixed(1)).toString();
        let fractional_part = value.split('.')[1];
        value = value.split('.')[0];
        value = value.split('').reverse().join('');
        const formatted_value = value.match(/.{1,3}/g).join(' ');
        value = formatted_value.split('').reverse().join('');
        if (fractional_part) {
            value += '.' + fractional_part;
        }   
    }
   return (<div className={`flex flex-col justify-center ${props.className} rounded-[1.5vw] bg-gray-100 h-[20vh] w-[15vw] px-[2vw]`}>
            <span className="text-5xl font-bold text-center">{value}</span>
            <span className="text-2xl font-semibold text-center mt-3 leading-none">{props.name}</span>
    </div>);
}

function Statistics(props) {
    const navigate = useNavigate();

    const total_messages = getStatistic("Total messages", "Global", "All time", /* from_date */ null, /* to_date */ null);
    const total_clients = getStatistic("Users", "Global", "All time", /* from_date */ null, /* to_date */ null);
    const message_per_user = getStatistic("Message per user", "Global", "All time", /* from_date */ null, /* to_date */ null);
    return (<div className={`flex ${props.className} justify-around items-center`}>
        <div className = "flex">
            <SingleStatistic name = "Total messages" value = {total_messages}/>
            <SingleStatistic name = "Clients" value = {total_clients} className = 'ml-12' />
            <SingleStatistic name = "Interactions per customer" value = {message_per_user} className = 'ml-12'/>
        </div>
        <CircledButtonWithName name="More stats" className = "flex flex-col" onClick = {() => {navigate('/stats/')}}/>
    </div>);
}


function Templates(props) {
    const [active_template, setActiveTemplate] = useState(null);
    const navigate = useNavigate();

    const hover_colors = ["hover:bg-purple-500",  "hover:bg-green-500", "hover:bg-yellow-500", "hover:bg-red-500", "hover:bg-yellow-500"
                                , "hover:bg-rose-500", "hover:bg-lime-500", "hover:bg-blue-500", "hover:bg-indigo-500", "hover:bg-cyan-500", "hover:bg-emerald-500"];
                                
    const colors_of_templates = ["bg-purple-700",  "bg-green-700", "bg-yellow-700", "bg-red-700", "bg-yellow-700",
                                "bg-rose-700", "bg-lime-700", "bg-blue-700", "bg-indigo-700", "bg-cyan-700", "bg-emerald-700"];
    
    const array_of_templates = [{name: "General template", description: "Tell about your business, and chatbot will answer any question", type : 'basic'},
                                {name: "Catalogue template", description: "Upload your products, and chatbot will help to find the right one", type: 'offers'},
                               ];
                                
    const handleTemplateClick = (template, index) => {
        return () => {
            const description = {
                'popularity': '10',
                'name': template.name,
                'description': template.description,
                'color': colors_of_templates[index % colors_of_templates.length],
                'button_hover_color': hover_colors[index % hover_colors.length],
                'try_click': handleTryClick(template, index),
                'type': template.type || 'basic'
            };
            setActiveTemplate(description);
        };
    };

    const handleTryClick = (template, index) => {   
        return () => {
            console.log("Try button clicked");
            navigate('/brains', {state: {template: template}});
        }
    };

    return (<div className="ml-12 mt-10 grid grid-cols-3 gap-8 ">
        {array_of_templates.map((obj, index) => (
        <Template key={index} name={array_of_templates[index % array_of_templates.length].name} description={array_of_templates[index % array_of_templates.length].description} 
                    className={`cursor-pointer ${colors_of_templates[index % colors_of_templates.length]} ${hover_colors[index % hover_colors.length]} `} 
            onClick={handleTemplateClick(obj, index)} />
        ))}
        {active_template && SingleShadowedTemplateDescription({...active_template, setActiveTemplate})}
    </div>);
}

function RightPanel() {
    const navigate = useNavigate();
    const [name, setName] = useState(null);
    getBusinessInfo().then((info) => {
        setName(info.data.name);
    }).catch((error) => {
        toast.error("Failed to get business info");
    });
    if (name != null) {
        return (<div className="flex justify-center flex-col w-[85%] h-[100vh]">
            <span className="ml-10 mt-10 text-4xl font-bold">Hey, {name}</span>
            <span className='ml-10 text-xl font-semibold mt-2'>Here is you chatbot overview:</span>
            <div className="ml-10 border-gray-200 border-b-2 mr-10 mt-5"></div>
            <Statistics className = "mt-10 "/>
            <div className="ml-10 border-gray-200 border-b-2 mr-10 mt-10"></div>
            <span className="ml-10 mt-5 text-4xl font-bold">Use the best of AI world with templates</span>
            <Templates className = " mt-8"/>
            <CircledButtonWithName name="All templates" className = "mt-5 flex flex-col items-center" onClick = {() => {navigate("/templates/")}} />
        </div>);
    }
}

function HomePage() {
    return (<div className = "flex ">
            <ToastContainer />
            <SideBar active_button="Home" />
            <div className="child-line transform -translate-x-1/2 h-[100vh] w-0.5 bg-gray-200" />
            <RightPanel />
        </div>);
}

export default HomePage;