
import { axiosPrivate } from './axios';

async function updateUserToken(token, platform, metadata = {}) {
    console.log(" METADATA IS " , metadata)
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND_URL}/register_messenger`, {
        token: token,
        platform: platform,
        metadata : metadata
    });
}
export async function updateUserTokenPreserveLink(token, platform, metadata = {}) {
    console.log(" METADATA IS " , metadata)
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND_URL}/update_messenger/${platform}`, {
        token: token,
        metadata : metadata
    });
}
export default updateUserToken;
