import React, {useState, useRef, useEffect} from 'react';
import SideBar from './SideBar.tsx'
import IconButton from './IconButton.tsx';
import global_channel_logo from '../img/global_channel_logo.svg';
import instagram_channel_logo from '../img/instagram_channel_logo.svg';
import telegram_channel_logo from '../img/telegram_channel_logo.svg';
import whatsapp_channel_logo from '../img/whatsapp_channel_logo.svg';
import messenger_channel_logo from '../img/messenger_channel_logo.svg';
import widget_channel_logo from '../img/widget_channel_logo.svg';
import Chats from './chat/Chats.tsx';
import ChatRoom from './chat/ChatRoom.tsx'

function ChannelsList({changePlatform}) {
    const channels = ['Instagram', 'Telegram', 'WhatsApp', 'Messenger', 'Widget'];
    const channel_icons = [instagram_channel_logo, telegram_channel_logo, whatsapp_channel_logo, messenger_channel_logo, widget_channel_logo];
    const [active_channel, set_active_channel] = useState('Instagram');
    useEffect(()=>{
        if(changePlatform){
            changePlatform(active_channel)
        }
    }, [active_channel])
    return (<div className="flex flex-col ml-10 mt-10 w-[15vw]">
        <span className="text-3xl font-bold ml-5">Channels</span>
        {channels.map((channel, index) => {
            return <IconButton key = {index} className = {`mt-5 w-full hover:bg-gray-500`} image_className = {active_channel == channels[index] ? "filter invert grayscale": ""} name={channel} icon_url={channel_icons[index]} onClick = {() => {set_active_channel(prev_active_channel => channel)}} 
                    background_color = {channels[index] == active_channel ? 'bg-black' : 'bg-white'}
                    text_className = {channels[index] == active_channel ? 'text-white' : 'text-black'} 
                    />;
        })}
    </div>);
}


function RightPanel(props) {
    const [platform , setPlatform] = useState('WhatsApp');
    const [selected_chat, setSelectedChat] = useState(null);
    const [has_token, setHasToken] = useState(false);

    useEffect(()=>{
        setHasToken(false);
    }, [platform]);

    return (<div className={`flex flex-col w-[85%] h-[100vh]  ${props.className}`}>
        <span className="ml-[5vw] mt-10 text-5xl font-bold">Live Chat</span>
        <div className="ml-10 border-gray-100 border-b-2 mr-10 mt-4"></div>
        <div className="flex h-full w-full">
            <ChannelsList changePlatform={setPlatform} />
            <div className="ml-6 child-line transform -translate-x-1/2 h-full w-0.5 bg-gray-100" />
            <div className="flex w-full">
                <div className="w-[30%] h-full">
                 <Chats platform={platform} selected_chat = {selected_chat} setSelectedChat={setSelectedChat} setHasToken={setHasToken}/>
                </div>
                <div className="w-[70%] h-full overflow-x-hidden">
                 
                <ChatRoom user={selected_chat} platform={platform} has_token = {has_token} />

                </div>
            </div>
        </div>
    </div>);
}


function LiveChatPage() {
    // block bot from responding to messages, by updating active time
    return (<div className = "flex ">
            <SideBar active_button="Live Chat" />
            <div className="child-line transform -translate-x-1/2 h-[100vh] w-0.5 bg-gray-100" />
            <RightPanel />
        </div>);
}

export default LiveChatPage;