import React from 'react';
import try_template_logo from '../img/try_template_logo.svg';

export default function TemplateDescription({ setActiveTemplate, name, description, popularity, color, button_hover_color, try_click, ...props}) {
    const textWithBreaks = <div>
        {description.split('\n').map((part, index) => (
            <span key={index}>
                {part}
                {/* Add a <br> tag except for the last part */}
                {index < description.split('\n').length - 1 && <div><br/></div>}
            </span>
        ))}
    </div>;
    const handleChooseAnotherClick = () => {
        setActiveTemplate(null);
    };
    return (<div className = {`flex flex-col ${props.className} bg-white pb-10 rounded-[1vw] overflow-hidden w-[35vw]`}>
        <div className = {`flex justify-between flex-row items-center ${color} px-7 pt-7 pb-4`}>
            <span className="text-4xl font-bold text-white">{name}</span>
            <span className="text-2xl font-bold text-white"> Popularity {popularity}/10</span>
        </div>
        <span className="text-2xl font-semibold text-black mt-7 pl-7 pr-[9%]">
            {textWithBreaks}
        </span>
        <div className = "flex flex-row items-start pl-7">
            <button className = {`${color} font-bold text-xl text-white px-4 py-2 rounded-lg mt-7 flex flex-row items-center ${button_hover_color}`}
                onClick={try_click}>
                Try it!
                <img src = {try_template_logo} alt = {name} className = "w-6 h-6 ml-2" />
            </button>
            <button className = "bg-white font-bold border border-black text-black px-4 py-2 rounded-lg text-xl mt-7 ml-4 hover:bg-gray-200" onClick={handleChooseAnotherClick}>I want to choose another template</button>
        </div>
    </div>
    );
}
