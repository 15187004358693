import React from 'react';

import { ToastContainer } from 'react-toastify';
import { InputField } from './InputField';
import { changePassword, logoutAccount } from '../hooks/authorization';
import { getBusinessInfo, updateBusinessInfo } from '../hooks/getBusinessInfo';

const text = `Privacy Policy

Effective Date: June 12th 2024

1. Introduction

Welcome to ChatBot Creation ("we", "our", "us"). We are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, and disclose information about you when you use our Meta business app ("the App").

2. Information We Collect

We may collect and process the following data about you:

2.1. Information You Provide to Us:

Account Information: When you create an account, we collect information such as your name, email address, phone number, and business details.
Profile Information: Information about your business profile, including business name, logo, description, and contact details.
Customer Data: Data about your customers that you input into the App, such as names, email addresses, and phone numbers.
Payment Information: If you make a purchase, we collect payment information necessary to process the transaction.
2.2. Information We Collect Automatically:

Usage Data: We collect information about your interactions with the App, such as access dates and times, features used, and pages viewed.
Device Information: We collect information about the device you use to access the App, including the device type, operating system, and IP address.
Cookies and Tracking Technologies: We use cookies and similar tracking technologies to track activity on the App and hold certain information.
3. How We Use Your Information

We use the information we collect to:

Provide, operate, and maintain the App.
Improve, personalize, and expand our App.
Understand and analyze how you use our App.
Develop new products, services, features,`;

function PrivacyPolicy() {
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '90%', fontSize: '18px', padding: '20px' }}>
                <pre style={{ whiteSpace: 'pre-wrap' }}>{text}</pre>
            </div>
            <ToastContainer />
        </div>
    );
}

export default PrivacyPolicy;