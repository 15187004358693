import React from 'react';
import our_logo from '../img/logo.svg';

export default function Logo(props) {
    return (
        <div className={`${props.className} flex items-center bg-white p-2`}>
            <img src={our_logo} alt="logo" className = "w-10 h-10"/>
            <h1 className="text-3xl font-bold ml-2">Agentify</h1>
        </div>
    );
}
