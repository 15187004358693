import { toast } from 'react-toastify';
export const success_notification = (message: string) => {
    toast.success(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
}
export const error_notification = (message: string) => {
    toast.error(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
}

export function transform_url(url){
    console.log(url)
    if (typeof url === 'string' && url.startsWith('<svg')) {
        return url;
    }
    url = String(url);
    if(!url.startsWith('http')){
        url = process.env.REACT_APP_BACKEND_URL + "/" + url;
    }
    return url;
}
