import React, {useState, useRef, useEffect} from 'react';
import SideBar from './SideBar.tsx'
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { InputField } from './InputField.tsx';
import { changePassword, logoutAccount } from '../hooks/authorization.tsx';
import {getBusinessInfo} from '../hooks/getBusinessInfo.tsx';
import { updateBusinessInfo } from '../hooks/getBusinessInfo.tsx';

function ChangePassword({setShowChangePassword}) {
    const password = useRef(null);
    const confirm_password = useRef(null);
    const [is_password_visible, setPasswordVisibility] = useState(false);
    const [is_confirm_password_visible, setConfirmPasswordVisibility] = useState(false);

    const handleSaveChangesClick = () => {
        if (password.current.value != confirm_password.current.value) {
            toast.error('Passwords do not match!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        } else if (password.current.value.length < 8 ) {
            toast.error('Password is too small!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        } else {
            // send request to change password
            changePassword(password.current.value).then((result) => {
                toast.success('Password changed successfully', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setShowChangePassword(false);
            }).catch((error) => {
                toast.error('Error while changing password!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            });
        }
    };
    

    function handleCancelClick() { 
        setShowChangePassword(false);
    };

    const handleShadowClick = (event) => {
        if (event.target.className.includes('shadow-change-password')) {
            setShowChangePassword(false);
        }
    };

    return (<div className = "shadow-change-password absolute left-0 top-0 h-full w-full z-10 flex justify-center items-center bg-black bg-opacity-80" onClick={handleShadowClick}>
        <div className = "bg-white rounded-xl w-[30vw] px-7 pt-7 pb-5">
            <span className="text-xl font-bold">Change password</span>
            <InputField name = "New password" reff = {password} className = "mt-7" type = "password" placeholder = "Use at least 8 characters"  has_visibible_property = {true} 
                                                is_visible = {is_password_visible} setVisibility = {setPasswordVisibility} />
            <InputField name = "Confirm new password" reff = {confirm_password} className = "mt-7" type = "password" placeholder = "Use at least 8 characters"  has_visibible_property = {true} 
                                                is_visible = {is_confirm_password_visible} setVisibility = {setConfirmPasswordVisibility} />                                    

            <div className = "flex flex-row justify-between">
                <button className = "bg-gray-300 w-1/2 text-black px-5 py-2 rounded-lg hover:bg-gray-400 mt-5" onClick={handleCancelClick}>Cancel</button>
                <button className = "bg-black w-1/2 ml-5 text-white px-5 py-2 rounded-lg hover:bg-gray-600 mt-5" onClick = {handleSaveChangesClick}>Change password</button>
            </div>
        </div>
    </div>);    
}


function AvatarName({name}) {
    console.log(name);
    const colors = ["bg-red-600", "bg-blue-600", "bg-green-600", "bg-yellow-600", "bg-indigo-600", "bg-purple-600", "bg-pink-600"];
    const color_id = Math.floor(Math.random() * colors.length);
    console.log(color_id);
    return (<div className={`flex items-center justify-center rounded-full w-24 h-24 ${colors[color_id]}`}>
        <div className="font-bold text-white text-4xl">
            {name != null && name != undefined ? name[0].toUpperCase() : "W"}
        </div>
    </div>)
}

function Profile() {
    const [show_change_password, setShowChangePassword] = useState(false);
    const [loadedData, setLoadedData] = useState(null);
    const name = useRef(null);
    const email = useRef(null);
    const navigate = useNavigate();

    const handleChangePasswordClick = () => {
        setShowChangePassword(true);
    };

    useEffect(() => {
        getBusinessInfo().then((response) => {
            if (response.data) {
                setLoadedData({
                    name: response.data.name,
                    email: response.data.login,
                    receive_emails: response.data.receive_emails
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const handleSaveChangesClick = () => {
        updateBusinessInfo(name.current.value, email.current.value, loadedData.receive_emails ).then((response) => {
            toast.success('Profile updated successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        });
    };

    const handleLogoutClick = () => {
        logoutAccount();
        navigate('/login/');
    };
        
    
    return (
            <div className = "flex flex-col h-full px-[15vw] pt-[5vh]">
                <div className = "flex flex-row items-start">
                    <div className = "flex flex-row items-center">
                        <AvatarName name={loadedData?.name} />
                        <span className = "font-bold text-2xl ml-8"> {loadedData?.name ? loadedData?.name : "Unknown"} </span>

                    </div>
                    <button className = "bg-red-600 w-[20%] text-white px-5 py-2 rounded-lg hover:bg-red-400 ml-auto mr-[5vw]" onClick={handleLogoutClick}>Log out</button>
                </div>
                <span className="text-2xl mt-7 font-bold">Details</span>
                <div className=" border-gray-200 border-b-2 mt-2"></div>
                <InputField reff={name} className = "w-[70%] mt-2" name="Name" type="text" is_small={true} placeholder="Name" value = {loadedData?.name} has_visibible_property={false}/>
                <InputField reff={email} className = "w-[70%] mt-2" name="Email" type="text" is_small={true} placeholder="Email" value = {loadedData?.email} blocked={true} has_visibible_property={false}/>
                
                <span className="text-2xl font-bol mt-7 font-bold">Change password</span>
                <div className=" border-gray-200 border-b-2 mt-2"></div>
                <span className="text-lg text-gray-500">Click on the button to change your password</span>
                <button onClick = {handleChangePasswordClick} className = "w-[20%] bg-black text-white px-5 py-3 rounded-lg hover:bg-gray-600 mt-5">Change my password</button>                
                <div className = "flex justify-center items-center mt-[10vh]">
                    <button className = "bg-gray-300 w-1/2 text-black px-5 py-2 rounded-lg hover:bg-gray-400" onClick={handleSaveChangesClick}>Save Changes</button>
                </div>

                {show_change_password ? <ChangePassword setShowChangePassword={setShowChangePassword} /> : null}
            </div>);
}

function RightPanel() {
    return (<div className="flex flex-col w-[85%] h-[100vh]">
        <span className="ml-[5vw] mt-10 text-5xl font-bold">Profile</span>
        <div className="ml-10 border-gray-200 border-b-2 mr-10 mt-4"></div>
        <div className = "relative h-full w-full">
            <Profile />
        </div>
    </div>);
}

function ProfilePage() {
    return (<div className = "flex ">
             <ToastContainer />
            <SideBar active_button="My Profile" />
            <div className="child-line transform -translate-x-1/2 h-[100vh] w-0.5 bg-gray-200" />
            <RightPanel />
        </div>);
}

export default ProfilePage;