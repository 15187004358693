import React, {useState, useRef, useEffect, useMemo} from 'react';
import SideBar from './SideBar.tsx'
import IconButton from './IconButton.tsx';
import global_channel_logo from '../img/global_channel_logo.svg';
import instagram_channel_logo from '../img/instagram_channel_logo.svg';
import telegram_channel_logo from '../img/telegram_channel_logo.svg';
import whatsapp_channel_logo from '../img/whatsapp_channel_logo.svg';
import messenger_channel_logo from '../img/messenger_channel_logo.svg';
import widget_channel_logo from '../img/widget_channel_logo.svg';
import stat_users_logo from '../img/stat_users_logo.svg';
import stat_messages_logo from '../img/stat_messages_logo.svg';
import stat_message_per_user_logo from '../img/stat_message_per_user_logo.svg';
import stat_initiated_convos_logo from '../img/stat_initiated_convos_logo.svg';
import stat_conversations_per_user_logo from '../img/stat_conversations_per_user_logo.svg';
import getStatistic from '../hooks/getStatistic.tsx';

function ChannelsList(props) {
    const channels = ['Global', 'Instagram', 'Telegram', 'WhatsApp', 'Messenger', 'Widget'];
    const channel_icons = [global_channel_logo, instagram_channel_logo, telegram_channel_logo, whatsapp_channel_logo, messenger_channel_logo, widget_channel_logo];
    const active_channel = props.active_channel;
    const set_active_channel = props.set_active_channel;    

    return (<div className="flex flex-col ml-10 mt-10 w-[15vw]">
        <span className="text-3xl font-bold ml-5">Channels</span>
        {channels.map((channel, index) => {
            return <IconButton key = {index} className = {`mt-5 w-full hover:bg-gray-500`} image_className = {active_channel == channels[index] ? "filter invert grayscale": ""} name={channel} icon_url={channel_icons[index]} onClick = {() => {set_active_channel(prev_active_channel => channel)}} 
                    background_color = {channels[index] == active_channel ? 'bg-black' : 'bg-white'}
                    text_className = {channels[index] == active_channel ? 'text-white' : 'text-black'} />;
        })}
    </div>);
}

function TimePeriodsList(props) {
    const active_time_period = props.active_time_period;
    const set_active_time_period = props.set_active_time_period;
    const time_periods = ['Last 7 days', 'Last 30 days', 'All time'];

    return (<div className= {`flex flex-col mt-3 w-full ${props.className}`}>
        <div className="flex justify-evenly w-full">
            {time_periods.map((time_period, index) => {
                return <span key = {index} className = {` text-3xl p-4 rounded-lg font-semibold ${active_time_period == time_periods[index] ? "border-2 border-black" : ""}`} onClick = {() => {set_active_time_period(prev_active_time_period => time_period)}} >
                        {time_periods[index]}
                    </span>;
            })}
        </div>
    </div>);

}

function SingleStat(props) {
    return (<div className = {`w-[20vw] rounded-[1.5vw] bg-gray-100 relative ${props.className}`}>
        <div className = "flex flex-col justify-center h-[10vw] ml-10">
            <div className = "flex-shrink-0 self-auto items-center flex flex-col max-w-[5vw] text-center ">
                <span className = "text-5xl font-bold leading-none">{props.value}</span>
                <span className = "text-xl font-bold leading-none mt-2">{props.name}</span>
            </div>
        </div>
        {props.photo_url ? 
            <img src = {props.photo_url} alt = {props.name} className = "absolute right-[1vw] bottom-[0vh] max-h-[15vh] max-w-[6vw]"/>
            : null}   
    </div>);
}

function SingleStatWithoutPhoto(props) {
    
    return (<div className = {`w-[20vw] rounded-[1.5vw] bg-gray-100 relative ${props.className}`}>
        <div className = "flex flex-col items-center justify-center h-[10vw]">
                <span className = "text-5xl font-bold leading-none">{props.value}</span>
                <span className = "text-xl font-bold leading-none mt-2">{props.name}</span>
        </div>
    </div>);
}

function StatsGrid(props) {
    const active_time_period = props.active_time_period;
    const stat_names = ['Users', 'Total messages', 'Message per user', 'Initiated convos', 'Conversations per user', 'Conversions'];
    const stat_photo_urls = [stat_users_logo, stat_messages_logo, stat_message_per_user_logo, stat_initiated_convos_logo, stat_conversations_per_user_logo, null];
    const platform = props.active_channel;
    return (<div className = {`grid grid-cols-3 ${props.className}`}>
        {stat_names.map((stat_name, index) => {
            let value = getStatistic(stat_names[index], platform, active_time_period, /* from_date */ null, /* to_date */ null);
            if (isNaN(Number(value))) {
                value = 'N/A';
            } else {
                if (Number.isInteger(Number(value))) {
                    value = Number(value).toString();
                } else {
                    value = Number(value).toFixed(1).toString();
                }
            }
            if (stat_photo_urls[index] == null) {
                return <SingleStatWithoutPhoto key = {index} name = {stat_name} value = {value} className = "m-2"/>
            }
            return <SingleStat key = {index} name = {stat_name} value = {value} photo_url = {stat_photo_urls[index]} className = "m-2"/>
        })}
    </div>);
}

function RightPanel(props) {
    const [active_time_period, set_active_time_period] = useState('Last 7 days');
    const [active_channel, set_active_channel] = useState('Global');
    return (<div className={`flex flex-col w-[85%] h-[100vh]  ${props.className}`}>
        <span className="ml-[5vw] mt-10 text-5xl font-bold">Stats</span>
        <div className="ml-10 border-gray-100 border-b-2 mr-10 mt-4"></div>
        <div className="flex h-full">
            <ChannelsList active_channel = {active_channel} set_active_channel = {set_active_channel}/>
            <div className="ml-6 child-line transform -translate-x-1/2 h-full w-0.5 bg-gray-100" />
            <div className="flex flex-col mt-7 w-full">
                <TimePeriodsList className = "" active_time_period = {active_time_period} set_active_time_period = {set_active_time_period} />
                <div className="border-gray-100 border-b-2 mr-10 mt-8"></div>
                <StatsGrid className = "ml-10 mt-10" active_time_period = {active_time_period} active_channel = {active_channel}/>
            </div>
        </div>
    </div>);
}


function StatsPage() {
    return (<div className = "flex ">
            <SideBar active_button="Stats" />
            <div className="child-line transform -translate-x-1/2 h-[100vh] w-0.5 bg-gray-100" />
            <RightPanel />
        </div>);
}

export default StatsPage;