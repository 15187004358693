import React, {useState, useRef, useEffect} from 'react';
import SideBar from './SideBar.tsx'
import our_logo from '../img/logo.svg';
import IconButton from './IconButton.tsx';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { toast, ToastContainer } from 'react-toastify';
import { loginAccount } from '../hooks/authorization.tsx';
import { setAxiosHeader } from '../hooks/axios.js';
import { InputField } from './InputField.tsx';


function LoginPage() {
    const email = useRef(null);
    const password = useRef(null);
    const [is_password_visible, setPasswordVisibility] = useState(false);
    const [accept_emails, setAcceptEmails] = useState(true);
    const navigate = useNavigate();

    function toggleAcceptEmails() {
        setAcceptEmails(prev_accept => !prev_accept);
    }

    function handleSignUp() {
        navigate('/register/');
    }

    function handleLogin() {
        console.log(email.current.value);
        console.log(password.current.value);
        const is_valid_email = validator.isEmail(email.current.value);
        if (email.current.value == null || !is_valid_email) {
            toast.error('Incorrect email!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }
        if (password.current.value == null || password.current.value.length < 8) {
            toast.error('Password is too small!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }
       
        loginAccount(email.current.value, password.current.value).then((result) => {
            console.log("Successful login");
            navigate('/home/');
        }).catch((error) => {
            toast.error('Incorrect email or password!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        });
    }

    return (
            <div className = "flex flex-row w-full h-full">
                <ToastContainer />
                <div className = "relative flex flex-col w-[30vw] h-full">
                    <img src={our_logo} alt="logo" className = " absolute right-[0vw] top-[15vh] w-[10vw] h-[20vh]"/>
                </div>
                <div className = "flex flex-col w-[45vw] h-full px-[5vw] pt-[10vh]">
                    <span className="text-5xl font-bold ">Welcome back to Agentify!</span>
                    <span className="text-xl text-gray-600 mt-5">
                        Manage your AI bots effortlessly across any social media platform. Register in just a few clicks and enhance your digital experience with powerful automation tools.
                    </span>
                    <InputField name = "Email" reff = {email} className = "mt-7 w-full" type = "email" placeholder = "Enter your email" has_visibible_property = {false} />
                    <InputField name = "Password" reff = {password} className = "mt-7 w-full" type = "password" placeholder = "Enter your password"  has_visibible_property = {true} 
                                                is_visible = {is_password_visible} setVisibility = {setPasswordVisibility} />
                    <div className = "flex flex-row mt-10 justify-center">
                        <button className = "bg-black text-white text-2xl rounded-[1vw] h-[7vh] w-[14vw] hover:bg-gray-800 " onClick = {handleLogin}>
                            Login
                        </button>
                    </div>
                    <div className = "flex flex-row mt-10">
                        <span className = "text-xl">Don't have an account?</span>
                        <button className = "text-xl font-bold ml-2" onClick = {handleSignUp}> Sign up</button>
                    </div>
                </div>
            </div>
       );
}

export default LoginPage;