import React, { useState } from 'react';
import axios from 'axios'
import PanelOptions from './PanelOptions.tsx';
import PanelOption from './PanelOption.tsx';
import Logo from './Logo.tsx'
import { useNavigate } from 'react-router-dom';

export default function SideBar({active_button}) {
    const navigate = useNavigate();
    return (
        <div className = "flex flex-col w-[15%] relative h-[100vh]"> 
            <Logo className = "ml-10 mt-10"/>
            <PanelOptions className = "ml-6 mt-8" active_button={active_button} />
            {/* bottom part */}
            <div className = "absolute bottom-20 w-full">
                <div className="w-full">
                    <div className="ml-[10%] border-gray-200 border-b-2 w-[80%]"></div>
                </div>
                <div className='flex flex-col'>
                    <PanelOption name="My Profile" className = "mt-2 ml-6" is_active={active_button == 'My Profile'} onClick = {() => {navigate("/profile");}} />
                    <PanelOption name="Help" className = "mt-2 ml-6" is_active={active_button == 'Help'} onClick = {() => {console.log("Help");}} />
                </div>
            </div>
        </div>
    );
}

