import React from "react"
import Avatar from "./Avatar.tsx"
import Name from "./Name.tsx"

const LIMIT = 20;
const shortenMessage = (text)=>{
    if(text.length <= LIMIT){
        return text
    }
    let show = text.slice(0, LIMIT-3);
    show += '...';
    return show;
}   

function getTimeRepresentation(time){
    let now = new Date();
    let then = new Date(time);
    function getTwoDigits(num) {
        return num < 10 ? "0" + num : num;
    }
    if ((now.getTime() - then.getTime()) < 24 * 60 * 60 * 1000){
        return getTwoDigits(then.getHours()) + ":" + getTwoDigits(then.getMinutes());
    } else if (then.getMonth() == now.getMonth() && then.getFullYear() == now.getFullYear() && 
                then.getDate() >= now.getDate() - 7){
        if (then.getDay() == 0) {
            return "Mon";
        } else if (then.getDay() == 1) {
            return "Tue";
        } else if (then.getDay() == 2) {
            return "Wed";
        } else if (then.getDay() == 3) {
            return "Thu";
        } else if (then.getDay() == 4) {
            return "Fri";
        } else if (then.getDay() == 5) {
            return "Sat";
        } else if (then.getDay() == 6) {
            return "Sun";
        }
    } else {
        return getTwoDigits(then.getDate()) + "." + getTwoDigits(then.getMonth()) + "." + then.getFullYear();
    }
}

function ChatElement(props){
    console.log(props?.lastMessage);
    getTimeRepresentation(props?.lastMessage?.timestamp);
    return (
        <div className={`${props.className} flex flex-row w-full h-[8vh] pt-[1vh] px-[1vw] ${props.is_chosen ? "bg-blue-500	" : " hover:bg-gray-200"}`} key={props?.user?.chat_id} onClick={(e)=>{
            props.callBack();
        }}>
            <Avatar className = "h-12 w-12" user_photo={props?.user?.user_photo} />

            <div className = "ml-5 flex flex-col">
                <Name className={`text-xl font-bold  ${props.is_chosen ? "text-white" : "text-black"} `} user = {props.user} />
                <p className={`${props.is_chosen ? " text-white" : "text-gray-500 font-bold"} text-lg mt-0.5`}>{props.lastMessage ? shortenMessage(props.lastMessage.content) : ""}</p>
            </div>  
            <div className={`ml-auto flex flex-col`}>
                <span className ={`${props.is_chosen ? "text-white" : "text-black"}`}>
                    {props?.lastMessage?.timestamp ? getTimeRepresentation(props.lastMessage.timestamp) : ""}
                </span>
                {props.user.n_unread_messages > 0
                ?   <div className = "rounded-full flex flex-col justify-center align-center bg-blue-400 text-white text-center h-7 w-7 ml-2 mt-1" >
                        <div>
                            {props.user.n_unread_messages}
                        </div>
                    </div> 
                : null}
                
            </div>
        </div>);
}

export default ChatElement;