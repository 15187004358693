import React from 'react';

function IconButton(props) {
return (
    <div className="flex">
    <button onClick = {props.onClick} className = {`${props.className} ${props.background_color} flex-shrink-0 self-auto flex items-center rounded-[1vw] bg-black text-white px-4 py-4`} disabled = {props.is_disabled}>
            <img src = {props.icon_url} alt = {props.name} className = {`${props.image_className} w-8 h-8`} />
            <span className = {`text-xl font-semibold ml-2 ${props.text_className}`} >{props.name}</span>
        </button>
    </div>)
}

export default IconButton;