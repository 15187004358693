// src/CopyCodeSnippet.js
import React, { useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const CopyCodeSnippet = ({ code }) => {
  const [tooltipText, setTooltipText] = useState('Click to copy');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code)
      .then(() => {
        setTooltipText('Copied!');
        setTimeout(() => setTooltipText('Click to copy'), 2000);
      })
      .catch(err => console.error('Failed to copy text: ', err));
  };

  return (
    <div 
      className="relative p-4 bg-gray-100 border border-gray-300 rounded-md cursor-pointer  hover:bg-gray-200" 
      data-tip 
      data-tooltip-id="copyTooltip" 
      onClick={copyToClipboard}
    >
      <div className = "overflow-hidden w-[20vw] max-h-[20vh]">
        <code className="break-all">{code}</code>
      </div>
      <ReactTooltip id="copyTooltip" place="top" effect="solid">
        {tooltipText}
      </ReactTooltip>
    </div>
  );
};

export default CopyCodeSnippet;
