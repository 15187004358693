
import {axiosPrivate} from './axios.js';
import React, { useEffect, useState, useCallback} from 'react';
import useDeepCompareEffect from './useDeepCompareEffect.tsx';

function getStatistic(statistic_name:string, platform: string, time_period: string | null, from_date: Date | null, to_date: Date | null) {
    const [info, setInfo] = useState(null);
    const loadingMessages = ['Loading.', 'Loading..', 'Loading...'];
    const [currentMessage, setCurrentMessage] = useState(0);
    const [is_fetching, setIsFetching] = useState(false);

    // Loading animation
    useEffect(() => {
        if (info != null) {
            return;
        }

        const intervalId = setInterval(() => {
            setCurrentMessage((prev) => (prev + 1) % loadingMessages.length);
        }, 400);

        // Cleanup interval when effect is done
        return () => clearInterval(intervalId);
    }, [info, loadingMessages]);

    
    useEffect(() => {
        if (is_fetching) return;
        console.log("Get statistic API call");
        setIsFetching(true);
        axiosPrivate.post(`${process.env.REACT_APP_BACKEND_URL}/get_statistic`, {
            statistic_name: statistic_name,
            platform: platform,
            from_date: (from_date ? from_date.toDateString() : null),
            to_date: (to_date ? to_date.toDateString() : null),
            time_period: time_period
        })
        .then(response => {
            if (response.data.result == null) {
                setInfo('N/A');
            }
            else {
                setInfo(response.data.result);
            }
        })
        .catch(error => {
            console.error("Error fetching data: ", error);
        })
        .finally(() => {
            setIsFetching(false);
        });
    }, [statistic_name, platform, from_date, to_date]);


    if (info == null) {
        return loadingMessages[currentMessage];
    }
    return info;
}

export default getStatistic;
