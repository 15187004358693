import { useEffect, useRef, useState } from 'react';
import _ from 'lodash'
function useConfirmModal() {
    // Store a reference to the last dependencies
    const [isOpen, setIsOpen] = useState(false);
    const [result, setResult] = useState(undefined)
    const [curCallback, setCurCallback] = useState([]);
    const [message, setMessage] = useState("Are you sure?");
    const askConfirmation = (cur_message, callback) => {
        setCurCallback([callback]);
        setIsOpen(true);
        setMessage(cur_message)
    }
    useEffect(()=>{
        if(result != undefined){

            console.log("the current call back is ", curCallback)
            curCallback[0](result);
            setResult(undefined);
            setIsOpen(false);
        }
    }, [result])




    return {askConfirmation, isOpen, confirmMessage: message, setResult}
}


export default useConfirmModal;